import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import Table from "../Table";
import QuickBooksLogo from "../../../assets/quickbooks-1.svg";
import InvoiceModal from "../InvoiceModal";
import { fetchAppointmentById } from "../../../store/thunks/appointmentsThunk";
import InvoicePaymentModal from "../../../modals/InvoicePaymentModal";
import ViewInvoicePDFModal from "../../../modals/ViewInvoicePDFModal";
import { setCurrentInvoice } from "../../../store/slices/invoiceSlice";
import { setCurrentPayment } from "../../../store/slices/paymentsSlice";

export const AppointmentInvoices = () => {
	const dispatch = useDispatch();
	const [showViewInvoiceModal, setShowViewInvoiceModal] = useState(false);
	const [showInvoiceModal, setShowInvoiceModal] = useState(false);
	const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const appointmentInvoices = useSelector((state) => state.invoices.appointmentInvoices);
  const appointmentPayments = useSelector((state) => state.payments.appointmentPayments);
	const invoices = useSelector((state) => state.invoices.appointmentInvoices);
	const roles = useSelector((state) => state.auth.user.role || []);
	const payments = useSelector((state) => state.payments.appointmentPayments);
  const selectedAppointment = useSelector((state) => state.appointments.selectedAppointment);
  const isLoading = useSelector((state) => state.invoices.loading || state.payments.loading);

	console.log(payments);
  console.log("Appointment Invoices", appointmentInvoices);

	const handleShowViewInvoiceModal = (invoiceId) => {
    const invoice = appointmentInvoices.find((invoice) => invoice._id === invoiceId);
    const payment = appointmentPayments.find((payment) => payment.invoiceId === invoiceId);
    dispatch(setCurrentPayment(payment)); 
    dispatch(setCurrentInvoice(invoice));
		setShowViewInvoiceModal(true);
	};

  const handleShowInvoiceModal = () => {
    setShowInvoiceModal(true);
  }

	const handleCollectPayment = () => {
		setIsPaymentModalOpen(true);
	};

	const handleSplitInvoice = () => {
		// setShowInvoiceModal(true);
	};

	return (
		<>
			<div className="border border-gray-200 rounded-md px-4 py-4">
				<div className="mt-2">
					<h3 className="text-base font-semibold leading-6 text-gray-900">
						Invoices
					</h3>
					<div className="border-t border-gray-100 px-2 py-2 sm:px-0">
						{isLoading ? (
              <div className="flex justify-center items-center py-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
              </div>
            ) : (
              roles.some((role) =>
                ["admin", "owner", "officeManager"].includes(role)
              ) &&
                (invoices.length === 0 ? (
                  <button
                    type="button"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleShowInvoiceModal}
                  >
                    Create Invoice
                  </button>
                ) : (
                  <Table
                    columns={[
                      {
                        header: "Invoice ID",
                        accessor: "invoiceId",
                      },
                      {
                        header: "Service Date",
                        accessor: (row) => {
                          const date = new Date(
                            row.serviceDate
                          );
                          return date.toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          );
                        },
                      },
                      {
                        header: "Invoice Date",
                        accessor: (row) => {
                          const date = new Date(
                            row.createdAt
                          );
                          return date.toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            }
                          );
                        },
                      },
                      {
                        header: "Client Name",
                        accessor: (row) =>
                          row.client
                            ? `${
                                row.client
                                  .firstName ||
                                "Unknown"
                              } ${
                                row.client
                                  .lastName || ""
                              }`
                            : "Unknown",
                      },
                      {
                        header: "Clinician",
                        accessor: (row) =>
                          row.clinician
                            ? `${
                                row.clinician
                                  .firstName ||
                                "Unknown"
                              } ${
                                row.clinician
                                  .lastName || ""
                              }`
                            : "Unknown",
                      },
                      {
                        header: "Amount",
                        accessor: (row) =>
                          `$${
                            row.amount?.toFixed(2) ||
                            "0.00"
                          }`,
                      },
                      {
                        header: "Split Invoice?",
                        accessor: (row) =>
                          row.splitBilling &&
                          row.splitBilling.isSplit
                            ? "Yes"
                            : "No",
                      },
                      {
                        header: "Status",
                        accessor: "status",
                      },
                      {
                        header: "QuickBooks",
                        accessor: (row) => (
                          <div className="flex items-center">
                            {row.QBOInvoiceId && (
                              <img
                                src={QuickBooksLogo}
                                alt="QuickBooks"
                                className="ml-2 h-5 w-5"
                                title="Loaded to QuickBooks"
                              />
                            )}
                          </div>
                        ),
                      },
                    ]}
                    data={invoices}
                    onRowClick={(invoice) => handleShowViewInvoiceModal(invoice)}
                  />
                ))
            )}
						{!isLoading && roles.some((role) =>
							["admin", "owner", "officeManager"].includes(role)
						) && (
							<>
								<div className="flex space-x-4 mt-4">
									{/* Show Collect Payment button only if any invoices are unpaid */}
									{invoices.some(invoice => {
										const hasValidPayment = payments?.some(payment => 
											payment.invoiceId._id === invoice._id && !payment.voided
										);
										return !hasValidPayment;
									}) && (
										<button
											type="button"
											className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
											onClick={handleCollectPayment}
										>
											Collect Payment
										</button>
									)}
									
									{/* Show Split Invoice button only if any invoice has splitBilling false */}
									{invoices.some(invoice => !invoice.splitBilling?.isSplit) && (
										<button
											type="button"
											className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
											onClick={handleSplitInvoice}
										>
											Split Invoice
										</button>
									)}
								</div>
							</>
						)}
					</div>
          {showInvoiceModal && (
            <InvoiceModal
              appointment={selectedAppointment}
              onClose={() => setShowInvoiceModal(false)}
            />
          )}
					{isPaymentModalOpen && (
						<InvoicePaymentModal
							onClose={async () => {
								setIsPaymentModalOpen(false); // Close the modal
							}}
						/>
					)}
          {showViewInvoiceModal && (
            <ViewInvoicePDFModal
              onClose={() => setShowViewInvoiceModal(false)}
            />
          ) }
				</div>
			</div>
		</>
	);
};
