import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modifyUser } from "../../../store/thunks/userThunk";
import { fetchClinics } from "../../../store/thunks/clinicThunk";

const UserClinicInformation = ({ user = {}, onComplete }) => {
  const dispatch = useDispatch();
  const clinics = useSelector((state) => state.clinics.clinics);
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [saved, setSaved] = useState(false);

  console.log("received user in userclinic", user);
  // Local state for managing selected clinics and default location
  const [selectedClinics, setSelectedClinics] = useState(
    user.clinics ? user.clinics.map((c) => c.clinic) : []
  );
  const [defaultLocation, setDefaultLocation] = useState(
    user.defaultLocation || ""
  );

  useEffect(() => {
    dispatch(fetchClinics()).then(() => setLoading(false));
  }, [dispatch]);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setSelectedClinics((prevSelected) =>
      checked
        ? [...prevSelected, value]
        : prevSelected.filter((id) => id !== value)
    );
  };

  const handleDefaultLocationChange = (e) => {
    setDefaultLocation(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSaving(true);
    setSaved(false);

    const updatedClinicianData = {
      clinics: selectedClinics.map((clinicId) => ({
        clinic: clinicId,
        isDefault: clinicId === defaultLocation,
      })),
      defaultLocation,
    };

    dispatch(
      modifyUser({
        userId: user._id || user.userId,
        userData: updatedClinicianData,
      })
    )
      .then((response) => {
        setIsSaving(false);
        setSaved(true);
        onComplete(response.payload);
        
        // Reset saved status after 2 seconds
        setTimeout(() => {
          setSaved(false);
        }, 2000);
      })
      .catch((error) => {
        setIsSaving(false);
        console.error("Error saving clinician information:", error);
      });
  };

  if (loading) return <div>Loading clinics...</div>;

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Clinics
        </label>
        <div className="mt-1">
          {clinics.map((clinic) => (
            <div key={clinic._id} className="flex items-center space-x-4">
              <input
                type="checkbox"
                id={`clinic-${clinic._id}`}
                value={clinic._id}
                checked={selectedClinics.includes(clinic._id)}
                onChange={handleCheckboxChange}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor={`clinic-${clinic._id}`} className="text-sm">
                {clinic.name}
              </label>
              <input
                type="radio"
                name="defaultLocation"
                value={clinic._id}
                checked={defaultLocation === clinic._id}
                onChange={handleDefaultLocationChange}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300"
              />
              <label className="text-sm text-gray-500">
                Set as Default Location
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="pt-5 flex justify-end">
        <button
          type="submit"
          disabled={isSaving}
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 disabled:bg-indigo-400">
          {isSaving ? 'Saving...' : saved ? 'Saved!' : 'Save Clinic Information'}
        </button>
      </div>
    </form>
  );
};

export default UserClinicInformation;
