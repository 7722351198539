import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

const MultiSelect = ({
    options,
    selectedValues = [],
    onChange,
    placeholder = "Select options...",
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selected, setSelected] = useState(selectedValues);
    const dropdownRef = useRef(null);

    useEffect(() => {
        setSelected(selectedValues); // Sync state with selectedValues prop
    }, [selectedValues]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleOption = (option) => {
        const isSelected = selected.find((item) => item.value === option.value);
        const updatedSelected = isSelected
            ? selected.filter((item) => item.value !== option.value)
            : [...selected, option];
        setSelected(updatedSelected);
        onChange(updatedSelected);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const clearAll = () => {
        setSelected([]);
        onChange([]);
    };

    const filteredOptions = options
        .filter(
            (option) =>
                option.label &&
                option.label.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            const isASelected = selected.some(item => item.value === a.value);
            const isBSelected = selected.some(item => item.value === b.value);
            return isASelected === isBSelected ? 0 : isASelected ? 1 : -1; // Sort selected items to the bottom
        });

    return (
        <div className="relative" ref={dropdownRef}>
            <div
                className="w-full md:w-auto mb-2 md:mb-0 mr-0 md:mr-4 p-2 border rounded cursor-pointer bg-white"
                onClick={() => setIsOpen(!isOpen)}
            >
                {placeholder}
            </div>
            {isOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border rounded shadow-lg">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="w-full p-2 border-b"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                    <ul className="max-h-60 overflow-y-auto">
                        {filteredOptions.map((option) => (
                            <li
                                key={option.value}
                                className={`p-2 cursor-pointer hover:bg-gray-200 ${
                                    selected.find(item => item.value === option.value)
                                        ? 'bg-blue-100'
                                        : 'text-gray-900'
                                } z-100`} // Added z-100 class here
                                onClick={() => toggleOption(option)}
                            >
                                {option.label}
                            </li>
                        ))}
                        {selected.length > 0 && (
                            <button
                                className="w-full p-2 text-sm text-red-600 hover:bg-red-100"
                                onClick={clearAll}
                            >
                                Clear All
                            </button>
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
};

MultiSelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedValues: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
};

export default MultiSelect;
