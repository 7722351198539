import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAppointmentsByDateRangeOnly } from "../../store/thunks/appointmentsThunk";
import { fetchClinicians } from "../../store/thunks/cliniciansThunk";
import { fetchMeetingsForCurrentWeek } from "../../store/thunks/meetingsThunk";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { setSelectedAppointment } from "../../store/slices/appointmentsSlice";
import { setSelectedMeeting } from "../../store/slices/meetingSlice";
import MultiSelect from "../../applicationUi/components/MultiSelect";
import CalendarEvent from "./scheduleComponents/CalendarEvent";
import QuickAddMeeting from "../../modals/QuickAddMeeting";
import AppointmentSummary from "../../applicationUi/components/appointments/AppointmentSummary";

const SevenDayCalendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [weekOffset, setWeekOffset] = useState(0);
  const [showWorkingHours, setShowWorkingHours] = useState(true);
  // const [selectedClinicians, setSelectedClinicians] = useState([]);
  const [showAppointments, setShowAppointments] = useState(true);
  const [showMeetings, setShowMeetings] = useState(true);
  const dispatch = useDispatch();
  const selectedAppointment = useSelector(
    (state) => state.appointments.selectedAppointment
  );
  const selectedMeeting = useSelector(
    (state) => state.meetings.selectedMeeting
  );
  const sevenDayAppointments = useSelector(
    (state) => state.appointments.sevenDayAppointments
  );
  const meetings = useSelector((state) => state.meetings.meetings);
  const user = useSelector((state) => state.auth.user);
  const clinicians = useSelector((state) => state.clinicians.clinicians || []);
  const loading = useSelector(
    (state) => state.appointments.loading || state.meetings.loading
  );
  const error = useSelector(
    (state) => state.appointments.error || state.meetings.error
  );

  console.log("7dayappts", sevenDayAppointments);
  console.log("meetings", meetings);

  // If the logged-in user is a clinician, include them in the initial selectedClinicians state
  const isUserClinician = clinicians.find(
    (clinician) => clinician._id === user._id
  );

  const [selectedClinicians, setSelectedClinicians] = useState(
    isUserClinician ? [isUserClinician] : []
  );

  const getCurrentWeek = (date) => {
    const week = [];
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - date.getDay());

    for (let i = 0; i < 7; i++) {
      const day = new Date(startOfWeek);
      day.setDate(startOfWeek.getDate() + i);
      week.push(day);
    }
    return week;
  };

  useEffect(() => {
    const currentWeek = getCurrentWeek(currentDate);
    const startDate = currentWeek[0];
    const endDate = currentWeek[6];
    dispatch(fetchAppointmentsByDateRangeOnly({ startDate, endDate }));
    dispatch(fetchMeetingsForCurrentWeek({ startDate, endDate }));
    dispatch(fetchClinicians());
  }, [dispatch, currentDate]);

  const handlePreviousWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - 7);
    setCurrentDate(newDate);
    setWeekOffset(weekOffset - 1);
  };

  const handleNextWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 7);
    setCurrentDate(newDate);
    setWeekOffset(weekOffset + 1);
  };

  const handleClinicianChange = (selectedOptions) => {
    console.log("Received this new selection:", selectedOptions);

    // Filter out null options to avoid adding them to the selection
    const chosenClinicians = selectedOptions
      .map((option) => option.value)
      .filter((clinician) => clinician !== null && clinician !== undefined);

    // Update selectedClinicians by combining previous selections with new ones
    setSelectedClinicians((prevSelected) => {
      const combined = [
        ...prevSelected.filter(
          (prevClinician) =>
            !chosenClinicians.some(
              (newClinician) => newClinician._id === prevClinician._id
            )
        ),
        ...chosenClinicians,
      ];
      console.log("Updated selected clinician objects:", combined);
      return combined;
    });
  };

  // Function to remove a clinician from the array by their ID
  const handleRemoveClinician = (clinicianId) => {
    setSelectedClinicians((prevSelected) =>
      prevSelected.filter((clinician) => clinician._id !== clinicianId)
    );
  };

  const pixelsPerMinute = 4;
  const startHour = showWorkingHours ? 7 : 0;
  const endHour = showWorkingHours ? 22 : 24; // Changed to 7pm (19:00)
  const totalMinutes = (endHour - startHour) * 60;
  const totalHeight = totalMinutes * pixelsPerMinute;

  // Prepare data for appointments and columns
  const currentWeek = getCurrentWeek(currentDate);

  const filteredAppointments = sevenDayAppointments?.filter(
    (appointment) =>
      selectedClinicians.length === 0 ||
      (appointment.clinician &&
        selectedClinicians.some(
          (clinician) => clinician._id === appointment.clinician._id
        ))
  );

  const filteredMeetings = meetings?.filter(
    (meeting) =>
      selectedClinicians.length === 0 ||
      meeting.attendees.some((attendee) =>
        selectedClinicians.some((clinician) => clinician._id === attendee)
      )
  );

  // Group appointments and meetings by day
  const eventsByDay = {};
  if (showAppointments) {
    filteredAppointments?.forEach((appointment) => {
      const start = new Date(appointment.start);
      const dayIndex = start.getDay();
      if (!eventsByDay[dayIndex]) {
        eventsByDay[dayIndex] = [];
      }
      eventsByDay[dayIndex].push({ ...appointment, isMeeting: false });
    });
  }

  if (showMeetings) {
    filteredMeetings?.forEach((meeting) => {
      const start = new Date(meeting.date);
      const dayIndex = start.getDay();
      if (!eventsByDay[dayIndex]) {
        eventsByDay[dayIndex] = [];
      }
      const end = new Date(start.getTime() + meeting.duration * 60000);
      eventsByDay[dayIndex].push({
        ...meeting,
        start: start.toISOString(),
        end: end.toISOString(),
        isMeeting: true,
      });
    });
  }

  const renderEvents = () => {
    const eventElements = [];
    const slotHeight = 15 * pixelsPerMinute; // Height per 15-minute block

    // Array to hold events by day
    const dayColumns = Array(7)
      .fill()
      .map(() => []);

    for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
      const dayEvents = eventsByDay[dayIndex];
      if (!dayEvents) continue;

      dayEvents.sort((a, b) => new Date(a.start) - new Date(b.start));

      // Assign each event to a column
      dayEvents.forEach((event) => {
        const start = new Date(event.start);
        const end = event.isMeeting
          ? new Date(start.getTime() + event.duration * 60000)
          : new Date(event.end);
        const duration = (end - start) / 60000; // Duration in minutes

        let placed = false;

        for (let i = 0; i < dayColumns[dayIndex].length; i++) {
          const lastEventInColumn =
            dayColumns[dayIndex][i][dayColumns[dayIndex][i].length - 1];
          const lastEnd = new Date(lastEventInColumn.end);

          // If no overlap, place event in this column
          if (start >= lastEnd) {
            dayColumns[dayIndex][i].push(event);
            event.column = i;
            placed = true;
            break;
          }
        }

        if (!placed) {
          dayColumns[dayIndex].push([event]);
          event.column = dayColumns[dayIndex].length - 1;
        }
      });

      const maxColumns = dayColumns[dayIndex].length;

      // Render each event in the calculated column and with appropriate height
      dayColumns[dayIndex].forEach((columnEvents, columnIndex) => {
        const columnWidth = 100 / maxColumns;

        columnEvents.forEach((event) => {
          const start = new Date(event.start);
          const startMinutes = start.getHours() * 60 + start.getMinutes();
          const duration = event.isMeeting
            ? event.duration
            : (new Date(event.end) - start) / 60000;

          const top = (startMinutes - startHour * 60) * pixelsPerMinute;
          const height = Math.ceil(duration / 15) * slotHeight;

          eventElements.push(
            <div
              key={event._id}
              style={{
                position: "absolute",
                left: `${columnIndex * columnWidth}%`,
                top: `${top}px`,
                width: `${columnWidth}%`,
                height: `${height}px`,
              }}
              className={`col-start-${dayIndex + 2}`}>
              <CalendarEvent
                appointment={event}
                onClick={() => {
                  if (event.isMeeting) {
                    dispatch(setSelectedMeeting(event));
                  } else {
                    dispatch(setSelectedAppointment(event));
                  }
                }}
                onRightClick={(e) => {
                  e.preventDefault();
                }}
                column={columnIndex}
                maxColumns={maxColumns}
                startHour={startHour}
              />
            </div>
          );
        });
      });
    }

    return eventElements;
  };

  // const renderEvents = () => {
  //   const eventElements = [];

  //   // Process each day separately
  //   for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
  //     const dayEvents = eventsByDay[dayIndex];
  //     if (!dayEvents) continue;

  //     // Sort events by start time
  //     dayEvents.sort((a, b) => new Date(a.start) - new Date(b.start));

  //     // Assign events to columns to handle overlaps
  //     const columns = [];

  //     dayEvents.forEach((event) => {
  //       const start = new Date(event.start);
  //       const startMinutes = start.getHours() * 60 + start.getMinutes();

  //       let duration;
  //       if (event.isMeeting) {
  //         duration = event.duration;
  //       } else {
  //         const end = new Date(event.end);
  //         duration = (end.getTime() - start.getTime()) / 60000; // Convert to minutes
  //       }

  //       // Find a column where this event doesn't overlap
  //       let placed = false;
  //       for (let i = 0; i < columns.length; i++) {
  //         const lastEventInColumn = columns[i][columns[i].length - 1];
  //         const lastEnd = new Date(lastEventInColumn.end);
  //         const lastEndMinutes = lastEnd.getHours() * 60 + lastEnd.getMinutes();

  //         if (startMinutes >= lastEndMinutes) {
  //           // No overlap, place it in this column
  //           columns[i].push(event);
  //           placed = true;
  //           break;
  //         }
  //       }
  //       if (!placed) {
  //         // Create a new column
  //         columns.push([event]);
  //       }
  //     });

  //     // Now render the events with adjusted positions
  //     const totalColumns = columns.length;

  //     columns.forEach((columnEvents, columnIndex) => {
  //       const columnWidth = 100 / totalColumns;

  //       columnEvents.forEach((event) => {
  //         const start = new Date(event.start);
  //         const startMinutes = start.getHours() * 60 + start.getMinutes();

  //         let duration;
  //         if (event.isMeeting) {
  //           duration = event.duration;
  //         } else {
  //           const end = new Date(event.end);
  //           duration = (end.getTime() - start.getTime()) / 60000; // Convert to minutes
  //         }

  //         const top = (startMinutes - startHour * 60) * pixelsPerMinute;
  //         const height = duration * pixelsPerMinute; // Calculate height based on duration

  //         const left = columnIndex * columnWidth;
  //         const width = columnWidth;

  //         eventElements.push(
  //           <div
  //             key={event._id}
  //             style={{
  //               position: "absolute",
  //               left: `${left}%`,
  //               top: `${top}px`,
  //               width: `${width}%`,
  //               height: `${height}px`, // Set height based on the duration
  //             }}
  //             className={`col-start-${dayIndex + 2}`}>
  //             <CalendarEvent
  //               appointment={event}
  //               onClick={() => {
  //                 if (event.isMeeting) {
  //                   dispatch(setSelectedMeeting(event));
  //                 } else {
  //                   dispatch(setSelectedAppointment(event));
  //                 }
  //               }}
  //               onRightClick={(e, evt) => {
  //                 e.preventDefault();
  //               }}
  //               column={columnIndex}
  //               maxColumns={totalColumns}
  //               startHour={startHour}
  //             />
  //           </div>
  //         );
  //       });
  //     });
  //   }

  //   return eventElements;
  // };

  // const renderEvents = () => {
  //   const eventElements = [];

  //   // Process each day separately
  //   for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
  //     const dayEvents = eventsByDay[dayIndex];
  //     if (!dayEvents) continue;

  //     // Sort events by start time
  //     dayEvents.sort((a, b) => new Date(a.start) - new Date(b.start));

  //     // Assign events to columns to handle overlaps
  //     const columns = [];

  //     dayEvents.forEach((event) => {
  //       const start = new Date(event.start);
  //       const end = new Date(event.end); // Ensure the end time is used correctly
  //       const startMinutes = start.getHours() * 60 + start.getMinutes();
  //       const endMinutes = end.getHours() * 60 + end.getMinutes();
  //       const duration = endMinutes - startMinutes;

  //       // Find a column where this event doesn't overlap
  //       let placed = false;
  //       for (let i = 0; i < columns.length; i++) {
  //         const lastEventInColumn = columns[i][columns[i].length - 1];
  //         const lastEnd = new Date(lastEventInColumn.end);
  //         const lastEndMinutes = lastEnd.getHours() * 60 + lastEnd.getMinutes();

  //         if (startMinutes >= lastEndMinutes) {
  //           // No overlap, place it in this column
  //           columns[i].push(event);
  //           placed = true;
  //           break;
  //         }
  //       }
  //       if (!placed) {
  //         // Create a new column
  //         columns.push([event]);
  //       }
  //     });

  //     // Now render the events with adjusted positions
  //     const totalColumns = columns.length;

  //     columns.forEach((columnEvents, columnIndex) => {
  //       const columnWidth = 100 / totalColumns;

  //       columnEvents.forEach((event) => {
  //         const start = new Date(event.start);
  //         const end = new Date(event.end);
  //         const startMinutes = start.getHours() * 60 + start.getMinutes();
  //         const endMinutes = end.getHours() * 60 + end.getMinutes();
  //         const duration = endMinutes - startMinutes;

  //         const top = (startMinutes - startHour * 60) * pixelsPerMinute;
  //         const height = duration * pixelsPerMinute; // Calculate height based on full duration

  //         const left = columnIndex * columnWidth;
  //         const width = columnWidth;

  //         eventElements.push(
  //           <div
  //             key={event._id}
  //             style={{
  //               position: "absolute",
  //               left: `${left}%`,
  //               top: `${top}px`,
  //               width: `${width}%`,
  //               height: `${height}px`, // Set height based on the duration
  //             }}
  //             className={`col-start-${dayIndex + 2}`}>
  //             <CalendarEvent
  //               appointment={event}
  //               onClick={() => {
  //                 if (event.isMeeting) {
  //                   dispatch(setSelectedMeeting(event));
  //                 } else {
  //                   console.log("setSelectedAppointment6");
  //                   dispatch(setSelectedAppointment(event));
  //                 }
  //               }}
  //               onRightClick={(e, evt) => {
  //                 e.preventDefault();
  //               }}
  //               column={columnIndex}
  //               maxColumns={totalColumns}
  //               startHour={startHour}
  //             />
  //           </div>
  //         );
  //       });
  //     });
  //   }

  //   return eventElements;
  // };

  const renderTimeSlots = () => {
    const slots = [];

    for (let hour = startHour; hour < endHour; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const time = new Date(2024, 0, 1, hour, minute);
        const isHour = minute === 0;
        const slotHeight = 15 * pixelsPerMinute; // 15-minute intervals

        slots.push(
          <div
            key={`${hour}-${minute}`}
            className="border-t border-gray-200 relative"
            style={{ height: `${slotHeight}px` }}>
            {isHour && (
              <span className="text-xs text-gray-500 absolute -mt-2">
                {time.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            )}
          </div>
        );
      }
    }
    return slots;
  };

  const renderEventPopup = () => {
    if (selectedAppointment) {
      console.log("selectedappt", selectedAppointment);
      return (
        <AppointmentSummary
          title="Appointment Details"
          open={true}
          onClose={() => {
            dispatch(setSelectedAppointment(null));
          }}
          appointment={selectedAppointment}
          onAppointmentClick={() => {}}
          onReschedule={() => {}}
        />
      );
    }

    if (selectedMeeting) {
      return (
        <QuickAddMeeting
          isOpen={true}
          onClose={() => {
            dispatch(setSelectedMeeting(null));
          }}
        />
      );
    }

    return null;
  };

  return (
    <div className="bg-white p-4">
      {/* The header */}
      <div className="flex flex-col space-y-4 mb-4">
        <div className="flex justify-between items-center">
          {/* Clinicians and selector */}
          <div className="flex flex-row mt-4">
            <div className="flex-shrink-0 pr-2 z-20">
              <label
                htmlFor="clinicians"
                className="block text-sm font-medium leading-6 text-gray-900">
                Clinicians
              </label>
              <div className="mt-2">
                <MultiSelect
                  options={clinicians.map((clinician) => ({
                    value: clinician,
                    _id: clinician._id,
                    label: `${clinician.firstName} ${clinician.lastName}`,
                  }))}
                  selectedValues={selectedClinicians}
                  onChange={handleClinicianChange}
                  placeholder="Select clinicians"
                  className="w-full"
                />
              </div>
            </div>
            <div className="flex-grow pl-2">
              <label
                htmlFor="attendees"
                className="block text-sm font-medium leading-6 text-gray-900">
                Viewing Schedule for:
              </label>
              <div className="border border-gray-200 rounded-md p-1.5 mt-2 flex flex-wrap gap-2">
                {selectedClinicians.length === 0 ? (
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-primary/10 text-primary">
                    All clinicians
                  </span>
                ) : (
                  selectedClinicians.map((clinician) => (
                    <span
                      key={clinician._id}
                      className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-200 text-primary">
                      {clinician.firstName} {clinician.lastName}
                      <button
                        type="button"
                        className="ml-2 text-red-500 hover:text-red-700"
                        onClick={() => handleRemoveClinician(clinician._id)}>
                        &times;
                      </button>
                    </span>
                  ))
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col items-end">
            {/* Show the current week being displayed */}
            <h2 className="text-xl font-bold flex items-center space-x-2 justify-end">
              <button
                onClick={handlePreviousWeek}
                className="p-2 rounded-full hover:bg-gray-100">
                <ChevronLeftIcon className="h-5 w-5" />
              </button>
              <span>{`${currentWeek[0]
                .toLocaleDateString("default", {
                  weekday: "short",
                  month: "short",
                  day: "numeric",
                })
                .replace(/,/g, "")}`}</span>
              <span> - </span>
              <span>{`${currentWeek[6]
                .toLocaleDateString("default", {
                  weekday: "short",
                  month: "short",
                  day: "numeric",
                })
                .replace(/,/g, "")}`}</span>
              <button
                onClick={handleNextWeek}
                className="p-2 rounded-full hover:bg-gray-100">
                <ChevronRightIcon className="h-5 w-5" />
              </button>
            </h2>

            {/* Checkboxes for views */}
            <div className="flex flex-col items-end space-x-4 flex-shrink-0 pl-2">
              <div className="mt-2 flex space-x-2 justify-end">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={showAppointments}
                    onChange={() => setShowAppointments(!showAppointments)}
                    className="mr-2"
                  />
                  Appointments
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    checked={showMeetings}
                    onChange={() => setShowMeetings(!showMeetings)}
                    className="mr-2"
                  />
                  Meetings
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={showWorkingHours}
                    onChange={() => setShowWorkingHours(!showWorkingHours)}
                    className="mr-2"
                  />
                  Working hours only
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative h-[calc(100vh-200px)] overflow-y-auto">
        {/* The calendar header */}
        <div className="grid grid-cols-8 gap-px sticky top-0 bg-white z-10">
          <div></div>
          {currentWeek.map((date, index) => (
            <div key={index} className="text-center font-semibold p-2">
              <div>
                {date.toLocaleDateString("default", { weekday: "short" })}
              </div>
              <div>{date.getDate()}</div>
            </div>
          ))}
        </div>

        {/* The calendar */}
        <div className="relative mt-2">
          <div
            className="absolute top-0 left-0 w-full"
            style={{ height: `${totalHeight}px` }}>
            <div className="grid grid-cols-8 h-full">
              <div className="pr-2">{renderTimeSlots()}</div>
              {Array.from({ length: 7 }).map((_, index) => (
                <div key={index} className="border-l border-gray-200">
                  {Array.from({ length: (endHour - startHour) * 4 }).map(
                    (_, slotIndex) => (
                      <div
                        key={slotIndex}
                        className="border-t border-gray-200"
                        style={{ height: `${15 * pixelsPerMinute}px` }}></div>
                    )
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="absolute top-0 left-0 w-full h-full grid grid-cols-8">
            <div></div>
            {Array.from({ length: 7 }).map((_, index) => (
              <div key={index} className="relative">
                {renderEvents().filter(
                  (event) => event.props.className === `col-start-${index + 2}`
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {loading && (
        <div className="text-center mt-4">
          Loading appointments and meetings...
        </div>
      )}
      {error && (
        <div className="text-center mt-4 text-red-500">
          Error loading appointments and meetings
        </div>
      )}
      {renderEventPopup()}
    </div>
  );
};

export default SevenDayCalendar;
