import React from "react";

const AppointmentButtonContainer = ({ appointment, onButtonClick }) => {
  const isCompletedOrTreated = appointment.history.some(
    (entry) => entry.status === "Completed" || entry.status === "Treated"
  );

  const canCancel = !isCompletedOrTreated;
  const canReschedule = !isCompletedOrTreated;
  const canChangeService = !isCompletedOrTreated;
  const canNotify = !isCompletedOrTreated;
  const canCreateSOAPNote = isCompletedOrTreated;

  // Define all status transitions
  const statusTransitions = {
    Booked: ["Tentative"],
    Tentative: ["Confirmed"],
    Confirmed: ["Treated"],
    Carryover: ["Tentative"],
    Treated: [],
    Cancelled: ["Tentative", "Confirmed"],
    Changed: ["Tentative"],
    Completed: [],
  };

  // Get the current status
  const currentStatus = appointment.status;

  // Get the next possible statuses based on the current status
  const nextStatuses = statusTransitions[currentStatus] || [];

  return (
    <div className="flex flex-col space-y-2">
      {/* Main Buttons (Cancel, Reschedule, Notify) */}
      <div className="flex space-x-2 overflow-x-auto">
        {canCancel && (
          <button
            className="rounded-md bg-red-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
            onClick={() => onButtonClick("cancel")}>
            Cancel
          </button>
        )}

        {canReschedule && (
          <button
            className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
            onClick={() => onButtonClick("reschedule")}>
            Reschedule
          </button>
        )}

        {canChangeService && (
          <button
            className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
            onClick={() => onButtonClick("changeService")}>
            Change Service
          </button>
        )}

        {appointment && (
          <button
            className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
            onClick={() => onButtonClick("modifyBlock")}>
            Modify Block
          </button>
        )}

        {canCreateSOAPNote && (
          <button
            className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
            onClick={() => onButtonClick("createSOAPNote")}>
            SOAP Notes
          </button>
        )}
      </div>

      {/* Mark as Buttons for Phase 1 (Treated, Changed, Cancelled) */}
      {nextStatuses.length > 0 && (
        <div className="flex space-x-2 overflow-x-auto">
          {nextStatuses.map((status, index) => (
            <button
              key={index}
              className="rounded-md bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
              onClick={() => onButtonClick(`status-${status}`)}>
              Mark as: {status}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default AppointmentButtonContainer;
