import { useSelector } from "react-redux";
import Table from "../Table";
import QuickBooksLogo from "../../../assets/quickbooks-1.svg";

export const AppointmentPayments = () => {
	const payments = useSelector(
		(state) => state.payments.appointmentPayments
	);
	const isLoading = useSelector((state) => state.payments.loading);
	const clinic = useSelector((state) => state.clinics.selectedClinic);

	return (
		<>
			<div className="border border-gray-200 rounded-md px-4 py-4">
				<div className="mt-2">
					<h3 className="text-base font-semibold leading-6 text-gray-900">
						Payments
					</h3>
					<div className="border-t border-gray-100 px-2 py-2 sm:px-0">
						{isLoading ? (
							<div className="flex justify-center items-center py-4">
								<div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
							</div>
						) : payments && payments.length > 0 ? (
							<Table
								columns={[
									{
										header: "Payment Date",
										accessor: (row) => {
											const date = new Date(
												row.paymentDate
											);
											return date.toLocaleDateString(
												"en-US",
												{
													year: "numeric",
													month: "long",
													day: "numeric",
												}
											);
										},
									},
									{
										header: "Payment Method",
										accessor: (row) => {
											const paymentMethod = clinic?.paymentMethods?.find(
												method => method.value === row.paymentMethod
											);
											return paymentMethod?.englishDescription || row.paymentMethod;
										},
									},
									{
										header: "Amount",
										accessor: (row) =>
											`$${
												row.amount?.toFixed(2) || "0.00"
											}`,
									},
									{
										header: "Invoice",
										accessor: (row) => row.invoiceId.invoiceId,
									},
									{
										header: "QuickBooks",
										accessor: (row) => (
											<div className="flex items-center">
												{row.QBOPaymentId && (
													<img
														src={QuickBooksLogo}
														alt="QuickBooks"
														className="ml-2 h-5 w-5"
														title="Loaded to QuickBooks"
													/>
												)}
											</div>
										),
									},
								]}
								data={payments}
							/>
						) : (
							<p className="text-sm text-gray-500 py-4">
								No payments found
							</p>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
