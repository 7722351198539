import React from "react";
import SevenDayCalendar from "./SevenDayCalendar";

export default function ClinicSchedule() {
  return (
    <>
      <SevenDayCalendar />
    </>
  );
}
