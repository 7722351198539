import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import MultiSelect from "../applicationUi/components/MultiSelect";
import SearchDropdown from "../applicationUi/components/SearchDropdown"; // Import SearchDropdown
import { fetchClinicians } from "../store/thunks/cliniciansThunk";
import {
  checkMeetingConflicts,
  createMeeting,
  updateMeetingThunk,
  deleteMeetingThunk,
} from "../store/thunks/meetingsThunk";
import { createAppointment } from "../store/thunks/appointmentsThunk"; // Import createAppointment thunk
import "react-datepicker/dist/react-datepicker.css";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Dialog } from "@headlessui/react";

// Helper function to round time to the nearest 5-minute interval
const roundTimeToNearest5Minutes = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  const roundedMinutes = Math.round(minutes / 5) * 5;
  return `${hours.toString().padStart(2, "0")}:${roundedMinutes
    .toString()
    .padStart(2, "0")}`;
};

const QuickAddMeeting = ({
  isOpen,
  onClose,
  initialDate,
  initialTime,
  initialClinicianFname,
  initialClinicianLname,
  selectedClinician,
}) => {
  const dispatch = useDispatch();
  const selectedMeeting = useSelector((state) => state.meetings.selectedMeeting);
  const [selectedDate, setSelectedDate] = useState(selectedMeeting ? new Date(selectedMeeting.date) : new Date());
  const [selectedClinicians, setSelectedClinicians] = useState(selectedMeeting ? selectedMeeting.attendees.map(attendee => ({
    value: attendee._id,
    label: `${attendee.firstName} ${attendee.lastName}`
  })) : []);
  const [meetingTitle, setMeetingTitle] = useState(selectedMeeting ? selectedMeeting.title : "");
  const [meetingDescription, setMeetingDescription] = useState(selectedMeeting ? selectedMeeting.description : "");
  const [meetingDuration, setMeetingDuration] = useState(selectedMeeting ? selectedMeeting.duration : 60); // in minutes
  const [meetingTime, setMeetingTime] = useState(selectedMeeting ? new Date(selectedMeeting.start).toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' }) : "");

  // New state for converting to appointment
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const selectedClinic = useSelector((state) => state.clinics.selectedClinic);

  console.log(meetingTime);

  console.log(
    "received the following via props:",
    "initialdate",
    initialDate,
    "initialtime",
    initialTime,
    "initialclinicianfname",
    initialClinicianFname,
    "initialclinicianlname",
    initialClinicianLname,
    "selectedclinician",
    selectedClinician,
    "clinic",
    selectedClinic,
  );
  const [isConvertingToAppointment, setIsConvertingToAppointment] =
    useState(false);
  const [message, setMessage] = useState("");

  const user = useSelector((state) => state.auth.user);
  const clinicians = useSelector((state) => state.clinicians.clinicians);

  // Check if editing an existing meeting or creating a new one
  const isUpdating = Boolean(selectedMeeting); // Flag to check if updating

  useEffect(() => {
    // Fetch clinicians and pre-populate the fields with initial props
    dispatch(fetchClinicians());

    if (initialDate) {
      setSelectedDate(new Date(initialDate));
    }

    if (initialTime) {
      setMeetingTime(roundTimeToNearest5Minutes(initialTime));
    }

    if (selectedClinician) {
      setSelectedClinicians([
        {
          value: selectedClinician._id,
          label: `${selectedClinician.firstName} ${selectedClinician.lastName}`,
        },
      ]);
    }
  }, [dispatch, initialDate, initialTime, selectedClinician]);


  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleClinicianChange = (selectedOptions) => {
    setSelectedClinicians(selectedOptions);
  };

  const handleTimeChange = (e) => {
    const roundedTime = roundTimeToNearest5Minutes(e.target.value);
    setMeetingTime(roundedTime);
  };

  const handleRemoveClinician = (clinicianId) => {
    setSelectedClinicians((prevSelected) =>
      prevSelected.filter((clinician) => clinician.value !== clinicianId)
    );
  };

  const handleSave = async (event) => {
    event.preventDefault();

    const updatedMeetingData = {
      clinic: selectedClinic._id,
      title: meetingTitle,
      date: selectedDate,
      startTime: meetingTime,
      duration: meetingDuration,
      attendees: selectedClinicians.map((clinician) => clinician.value),
      description: meetingDescription,
    };

    const conflictCheckResult = await dispatch(
      checkMeetingConflicts(updatedMeetingData)
    );

    if (conflictCheckResult.payload?.conflicts?.length > 0) {
      if (
        window.confirm(
          `${conflictCheckResult.payload.message}. Do you want to proceed?`
        )
      ) {
        if (isUpdating) {
          dispatch(
            updateMeetingThunk({
              id: selectedMeeting._id,
              updatedData: updatedMeetingData,
            })
          )
            .then(() => onClose())
            .catch((error) =>
              console.error("Failed to update meeting:", error)
            );
        } else {
          dispatch(createMeeting(updatedMeetingData))
            .then(() => onClose())
            .catch((error) =>
              console.error("Failed to create meeting:", error)
            );
        }
      }
    } else {
      if (isUpdating) {
        dispatch(
          updateMeetingThunk({
            id: selectedMeeting._id,
            updatedData: updatedMeetingData,
          })
        )
          .then(() => onClose())
          .catch((error) => console.error("Failed to update meeting:", error));
      } else {
        dispatch(createMeeting(updatedMeetingData))
          .then(() => onClose())
          .catch((error) => console.error("Failed to create meeting:", error));
      }
    }
  };

  const convertMeetingToAppointment = async (meetingId) => {
    console.log("Converting this meeting id", meetingId, "into appointment");

    if (!selectedClient || !selectedService) {
      setMessage("Please select both a client and a service."); // Use state for messages
      return;
    }

    const startTimeParts = meetingTime.split(":");
    const start = new Date(selectedDate);
    start.setHours(parseInt(startTimeParts[0]), parseInt(startTimeParts[1]));

    const end = new Date(start);
    end.setMinutes(start.getMinutes() + meetingDuration);

    const appointmentData = {
      clinic: selectedClinic._id,
      clinician: selectedClinicians[0]?.value || null,
      client: selectedClient._id,
      title: selectedService.description,
      start: start.toISOString(),
      end: end.toISOString(),
      service: selectedService._id,
      status: ["Booked"],
      history: [
        {
          status: "Booked",
          comments: "Appointment created from meeting",
          timestamp: new Date().toISOString(),
          user: user._id,
        },
      ],
    };

    console.log("Appointment Data:", appointmentData);

    try {
      const response = await dispatch(createAppointment(appointmentData));
      console.log("Dispatch Response:", response);

      // Check if the action is fulfilled
      if (response.type === "appointments/createAppointment/fulfilled") {
        console.log("Appointment created successfully:", response.payload);

        // Handle any warning messages returned from the server
        const warningMessage =
          response.payload.warning || response.payload.warnings?.join(", ");
        if (warningMessage) {
          setMessage(`Warning: ${warningMessage}`); // Show warning message if there's a conflict
        } else {
          setMessage("Appointment created successfully!"); // Success message
        }

        console.log("Deleting meeting:", meetingId);
        await handleDelete(meetingId); // Automatically delete the meeting
        setIsConvertingToAppointment(false); // Close the conversion UI
      } else {
        setMessage(
          "Failed to convert meeting to an appointment. Reason: " +
            (response.error?.message || "Unknown error.")
        );
      }
    } catch (error) {
      console.error("Error converting meeting to appointment:", error);
      setMessage(
        "Failed to convert meeting to an appointment. Please try again later."
      );
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this meeting?")) {
      try {
        await dispatch(deleteMeetingThunk(id));
        onClose(); // Close the modal after successful deletion
      } catch (error) {
        console.error("Failed to delete meeting:", error);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 z-60 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
        <Dialog.Overlay className="fixed inset-0 bg-gray-900 opacity-50" />
        <div className="relative transform overflow-hidden rounded-lg bg-white px-6 pb-6 pt-5 text-left shadow-xl transition-all sm:my-12 sm:w-full sm:max-w-4xl sm:p-8">
          <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
            <button
              type="button"
              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={onClose}>
              <span className="sr-only">Close</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
              <Dialog.Title
                as="h3"
                className="text-xl font-semibold leading-6 text-gray-900 mb-6">
                {isUpdating ? "Update Meeting" : "Quick Add Meeting"}
              </Dialog.Title>
              <div className="mt-4">
                <form onSubmit={handleSave}>
                  <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                      <div className="mt-10 grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-6">
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="attendees"
                            className="block text-sm font-medium leading-6 text-gray-900">
                            Attendees
                          </label>
                          <div className="mt-2 flex flex-wrap gap-2">
                            {selectedClinicians.map((clinician) => (
                              <span
                                key={clinician.value}
                                className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800">
                                {clinician.label}
                                <button
                                  type="button"
                                  className="ml-2 text-red-500 hover:text-red-700"
                                  onClick={() =>
                                    handleRemoveClinician(clinician.value)
                                  }>
                                  &times;
                                </button>
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="clinicians"
                            className="block text-sm font-medium leading-6 text-gray-900">
                            Clinicians
                          </label>
                          <div className="mt-2">
                            <MultiSelect
                              options={clinicians.map((clinician) => ({
                                value: clinician._id,
                                label: `${clinician.firstName} ${clinician.lastName}`,
                              }))}
                              selectedValues={selectedClinicians}
                              onChange={handleClinicianChange}
                              placeholder="Select clinicians..."
                              className="w-full"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="meeting-title"
                            className="block text-sm font-medium leading-6 text-gray-900">
                            Meeting Title
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="meeting-title"
                              id="meeting-title"
                              value={meetingTitle}
                              onChange={(e) => setMeetingTitle(e.target.value)}
                              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="meeting-description"
                            className="block text-sm font-medium leading-6 text-gray-900">
                            Description
                          </label>
                          <div className="mt-2">
                            <textarea
                              name="meeting-description"
                              id="meeting-description"
                              value={meetingDescription}
                              onChange={(e) =>
                                setMeetingDescription(e.target.value)
                              }
                              rows="4"
                              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        {/* Date Column */}
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="meeting-date"
                            className="block text-sm font-medium leading-6 text-gray-900">
                            Date
                          </label>
                          <div className="mt-2">
                            <DatePicker
                              selected={selectedDate}
                              onChange={handleDateChange}
                              dateFormat="MMMM d, yyyy"
                              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        {/* Time Column */}
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="meeting-time"
                            className="block text-sm font-medium leading-6 text-gray-900">
                            Time (nearest 5 minutes)
                          </label>
                          <div className="mt-2">
                            <input
                              type="time"
                              name="meeting-time"
                              id="meeting-time"
                              value={meetingTime}
                              onChange={handleTimeChange}
                              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              step="300" // 5-minute increments
                            />
                          </div>
                        </div>

                        {/* Duration Column */}
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="meeting-duration"
                            className="block text-sm font-medium leading-6 text-gray-900">
                            Duration (nearest 5 minutes)
                          </label>
                          <div className="mt-2">
                            <input
                              type="number"
                              name="meeting-duration"
                              id="meeting-duration"
                              value={meetingDuration}
                              onChange={(e) =>
                                setMeetingDuration(
                                  Math.max(
                                    5,
                                    Math.round(e.target.value / 5) * 5
                                  )
                                )
                              }
                              className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              step="5"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-8 flex items-center justify-between gap-x-6">
                    {isUpdating && (
                      <button
                        type="button"
                        onClick={() => handleDelete(selectedMeeting._id)}
                        className="rounded-md bg-red-400 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                        Delete Meeting
                      </button>
                    )}
                    <div className="flex items-center gap-x-6">
                      <button
                        type="button"
                        onClick={onClose}
                        className="text-sm font-semibold leading-6 text-gray-900">
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        {isUpdating ? "Update Meeting" : "Create Meeting"}
                      </button>
                    </div>
                  </div>
                </form>

                {/* Convert to Appointment button and form */}
                {!isConvertingToAppointment && (
                  <button
                    className="rounded-md bg-green-400 px-4 py-2 text-sm font-semibold text-white mt-4 hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    onClick={() => setIsConvertingToAppointment(true)}>
                    Convert Meeting to Appointment
                  </button>
                )}

                {/* Convert to Appointment UI */}
                {isConvertingToAppointment && (
                  <div className="mt-8 grid gap-y-6">
                    <h4 className="text-lg font-semibold">
                      Convert to Appointment
                    </h4>
                    <SearchDropdown
                      prefix=":client:"
                      displayFields={[
                        "firstName",
                        "lastName",
                        "email",
                        "phoneNumbers",
                      ]}
                      onSelection={(client) => setSelectedClient(client)}
                      placeholder="Search for an existing client"
                    />
                    <SearchDropdown
                      prefix=":service:"
                      displayFields={["serviceCode", "description", "price"]}
                      onSelection={(service) => setSelectedService(service)}
                      placeholder="Search for a service by name or service code"
                    />
                    <SearchDropdown
                      prefix=":clinic:"
                      displayFields={["name", "email"]}
                      placeholder="Search for a clinic location"
                    />
                    <div className="flex flex-col items-center">
                      <button
                        className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white mt-4 hover:bg-indigo-800"
                        onClick={() =>
                          convertMeetingToAppointment(selectedMeeting._id)
                        }>
                        Convert Meeting to Appointment
                      </button>
                      <p className="text-sm text-gray-500 pt-2">
                        Note: This will delete the meeting and create an
                        appointment.
                      </p>
                    </div>{" "}
                    {message && (
                      <p className="text-sm text-red-500 pt-2">{message}</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default QuickAddMeeting;
