import InputField from "../../applicationUi/components/intake/InputField";
import TextAreaField from "../../applicationUi/components/intake/TextAreaField";
import RadioGroup from "../../applicationUi/components/intake/RadioGroup";
const PreAuthorizedPaymentSection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold mb-4">
      M - Pre-authorized Credit Card Payment
    </h2>

    {/* Name and Address Fields */}
    <InputField
      label="Name"
      name="preAuthorizedPayment.name"
      value={formData.preAuthorizedPayment.name}
      onChange={handleChange}
      placeholder="Enter your full name"
    />
    <TextAreaField
      label="Address"
      name="preAuthorizedPayment.address"
      value={formData.preAuthorizedPayment.address}
      onChange={handleChange}
      placeholder="Enter your full address"
    />
    <div className="grid grid-cols-3 gap-4">
      <InputField
        label="City"
        name="preAuthorizedPayment.city"
        value={formData.preAuthorizedPayment.city}
        onChange={handleChange}
        placeholder="Enter city"
      />
      <InputField
        label="Province"
        name="preAuthorizedPayment.province"
        value={formData.preAuthorizedPayment.province}
        onChange={handleChange}
        placeholder="Enter province"
      />
      <InputField
        label="Postal Code"
        name="preAuthorizedPayment.postalCode"
        value={formData.preAuthorizedPayment.postalCode}
        onChange={handleChange}
        placeholder="Enter postal code"
      />
    </div>

    {/* Credit Card Information */}
    <RadioGroup
      label="Card Type"
      name="preAuthorizedPayment.cardType"
      options={[
        { value: "visa", label: "Visa" },
        { value: "masterCard", label: "Master Card" },
      ]}
      selectedValue={formData.preAuthorizedPayment.cardType}
      onChange={handleChange}
    />

    {/* Conditionally render input based on card type */}
    {formData.preAuthorizedPayment.cardType === "visa" && (
      <InputField
        label="Visa Card Number"
        name="preAuthorizedPayment.visaCardNumber"
        value={formData.preAuthorizedPayment.visaCardNumber}
        onChange={handleChange}
        placeholder="Visa card number"
      />
    )}
    {formData.preAuthorizedPayment.cardType === "masterCard" && (
      <InputField
        label="Master Card Number"
        name="preAuthorizedPayment.masterCardNumber"
        value={formData.preAuthorizedPayment.masterCardNumber}
        onChange={handleChange}
        placeholder="Master Card number"
      />
    )}

    {/* Expiry Date and Security Code */}
    <div className="grid grid-cols-3 gap-4">
      <InputField
        label="Expiry Month"
        name="preAuthorizedPayment.expiryMonth"
        value={formData.preAuthorizedPayment.expiryMonth}
        onChange={handleChange}
        placeholder="MM"
      />
      <InputField
        label="Expiry Year"
        name="preAuthorizedPayment.expiryYear"
        value={formData.preAuthorizedPayment.expiryYear}
        onChange={handleChange}
        placeholder="YYYY"
      />
      <InputField
        label="Security Code"
        name="preAuthorizedPayment.securityCode"
        value={formData.preAuthorizedPayment.securityCode}
        onChange={handleChange}
        placeholder="CVC"
      />
    </div>

    {/* Authorization Section */}
    <p className="font-semibold mt-6">
      I hereby authorize Kekoa Tree to debit my credit card for services.
    </p>
    <InputField
      label="Card Holder Signature"
      name="preAuthorizedPayment.cardHolderSignature"
      value={formData.preAuthorizedPayment.cardHolderSignature}
      onChange={handleChange}
      placeholder="Signature"
    />
    <InputField
      label="Date"
      name="preAuthorizedPayment.date"
      value={formData.preAuthorizedPayment.date}
      onChange={handleChange}
      placeholder="MM/DD/YYYY"
    />
  </section>
);

export default PreAuthorizedPaymentSection;
