import InputField from "../../applicationUi/components/intake/InputField";

const LimitsConfidentialitySection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold mb-4">K - Limits of Confidentiality</h2>

    <p className="mb-4">
      Please be aware that the information that you share in your session(s) are
      kept in your child's file (also called a Health Information Record).
      HearSay works hard to keep the information in your child's file
      confidential. However, there have been cases where HearSay has been court
      ordered to share information about your child’s service and we have had to
      do so.
    </p>

    <p className="font-semibold mb-4">
      If applicable, please provide the names and signatures below.
    </p>

    {/* Parent/Guardian #1 */}
    <div className="grid grid-cols-2 gap-4 w-full">
      <InputField
        label="Parent/Guardian #1 - Name/Signature"
        name="limitsOfConfidentiality.parent1Signature"
        value={formData.limitsOfConfidentiality.parent1Signature}
        onChange={handleChange}
      />{" "}
      <InputField
        label="Parent/Guardian #1 - Date"
        name="limitsOfConfidentiality.parent1Date"
        value={formData.limitsOfConfidentiality.parent1Date}
        onChange={handleChange}
      />
    </div>

    {/* Parent/Guardian #2 */}
    <div className="grid grid-cols-2 gap-4 w-full mt-4">
      <InputField
        label="Parent/Guardian #2 - Name/Signature"
        name="limitsOfConfidentiality.parent2Signature"
        value={formData.limitsOfConfidentiality.parent2Signature}
        onChange={handleChange}
      />
      <InputField
        label="Parent/Guardian #2 - Date"
        name="limitsOfConfidentiality.parent2Date"
        value={formData.limitsOfConfidentiality.parent2Date}
        onChange={handleChange}
      />
    </div>
  </section>
);

export default LimitsConfidentialitySection;
