import React from "react";

const SOAPTemplate = ({ appointmentData, soapData, onChange, readOnly }) => {
    console.log(appointmentData);
    const renderField = (label, name, type = "text") => (
        <div className="mb-6">
            <label
                htmlFor={name}
                className="block text-sm font-semibold leading-6 text-gray-900"
            >
                {label}:
            </label>
            {type === "textarea" ? (
                <textarea
                    id={name}
                    name={name}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={soapData[name]}
                    readOnly={readOnly}
                    onChange={(e) =>
                        onChange({ ...soapData, [name]: e.target.value })
                    }
                />
            ) : (
                <input
                    type={type}
                    id={name}
                    name={name}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={soapData[name]}
                    readOnly={readOnly}
                    onChange={(e) =>
                        onChange({ ...soapData, [name]: e.target.value })
                    }
                />
            )}
        </div>
    );

    return (
        <div className="soap-template">
            <h3 className="text-lg font-semibold leading-7 text-gray-900 mb-6">
                SOAP Template
            </h3>

            <div className="grid grid-cols-2 gap-4 mb-6">
                <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900">
                        CLIENT NAME:
                    </label>
                    <div className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 px-3">
                        {appointmentData.client.firstName + " " + appointmentData.client.lastName}
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900">
                        D.O.B (M/D/Y):
                    </label>
                    <div className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 px-3">
                        {new Date(appointmentData.client.dateOfBirth).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900">
                        TREATING CLINICIAN:
                    </label>
                    <div className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 px-3">
                        {appointmentData.clinician.firstName + " " + appointmentData.clinician.lastName}
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900">
                        SUPERVISING CLINICIAN:
                    </label>
                    <div className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 px-3">
                        {appointmentData.supervisingClinician ? 
                            appointmentData.supervisingClinician.firstName + " " + appointmentData.supervisingClinician.lastName :
                            "N/A"}
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900">
                        SESSION DATE:
                    </label>
                    <div className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 px-3">
                        {appointmentData.start ? new Date(appointmentData.start).toLocaleString('en-US', { 
                            year: 'numeric', 
                            month: 'long', 
                            day: 'numeric',
                            hour: 'numeric',
                            minute: '2-digit',
                            hour12: true 
                        }) : "N/A"}
                    </div>
                </div>
                <div>
                    <label className="block text-sm font-semibold leading-6 text-gray-900">
                        SESSION DURATION:
                    </label>
                    <div className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 px-3">
                        {(appointmentData.service.duration ? appointmentData.service.duration + " min" : "N/A")}
                    </div>
                </div>
            </div>

            {renderField("Sitting In", "sittingIn")}
            <div className="mb-6">
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                    Time of Arrival:
                </label>
                <div className="mt-2 flex items-center space-x-4">
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            id="onTime"
                            name="onTime"
                            checked={soapData.onTime}
                            onChange={(e) => onChange({ ...soapData, onTime: e.target.checked, late: false, arrivalTime: '' })}
                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        />
                        <label htmlFor="onTime" className="ml-2 block text-sm text-gray-900">On Time</label>
                    </div>
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            id="late"
                            name="late"
                            checked={soapData.late}
                            onChange={(e) => onChange({ ...soapData, late: e.target.checked, onTime: false, arrivalTime: e.target.checked ? soapData.arrivalTime : '' })}
                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        />
                        <label htmlFor="late" className="ml-2 block text-sm text-gray-900">Late</label>
                    </div>
                </div>
                {soapData.late && (
                    <div className="mt-2">
                        <label htmlFor="arrivalTime" className="block text-sm text-gray-700">How late (in minutes):</label>
                        <input
                            type="number"
                            id="arrivalTime"
                            name="arrivalTime"
                            value={soapData.arrivalTime}
                            onChange={(e) => onChange({ ...soapData, arrivalTime: e.target.value })}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="Enter minutes"
                        />
                    </div>
                )}
            </div>
            <div className="mb-6">
                <label className="block text-sm font-semibold leading-6 text-gray-900">
                    How did the client present:
                </label>
                <div className="mt-2 flex flex-wrap gap-4">
                    {['Task Oriented', 'Distracted', 'Excited', 'Distressed', 'Regulated', 'Dysregulated'].map((option) => (
                        <div key={option} className="flex items-center">
                            <input
                                type="checkbox"
                                id={`clientPresentation-${option}`}
                                name="clientPresentation"
                                value={option}
                                checked={soapData.clientPresentation && soapData.clientPresentation.includes(option)}
                                onChange={(e) => {
                                    const currentPresentation = soapData.clientPresentation || [];
                                    const updatedPresentation = e.target.checked
                                        ? [...currentPresentation, option]
                                        : currentPresentation.filter(item => item !== option);
                                    onChange({ ...soapData, clientPresentation: updatedPresentation });
                                }}
                                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            />
                            <label htmlFor={`clientPresentation-${option}`} className="ml-2 block text-sm text-gray-900">{option}</label>
                        </div>
                    ))}
                </div>
            </div>
            {renderField("Parent Reports", "parentReports", "textarea")}
            {renderField("Materials", "materials", "textarea")}
            <div className="mb-6">
                <h2 className="text-lg font-semibold leading-7 text-gray-900 mb-4">Objective</h2>
                
                {/* Goals Table */}
                <div className="mb-6">
                    <h3 className="text-sm font-semibold leading-6 text-gray-900 mb-2">Goals Table</h3>
                    {soapData.goals && soapData.goals.map((goal, index) => (
                        <div key={index} className="mb-4 p-4 border border-gray-300 rounded-md">
                            <div className="grid grid-cols-2 gap-4 mb-2">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Goal</label>
                                    <input
                                        type="text"
                                        value={goal.goal}
                                        onChange={(e) => {
                                            const updatedGoals = [...soapData.goals];
                                            updatedGoals[index].goal = e.target.value;
                                            onChange({ ...soapData, goals: updatedGoals });
                                        }}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700"># of Opportunities</label>
                                    <input
                                        type="number"
                                        value={goal.opportunities}
                                        onChange={(e) => {
                                            const updatedGoals = [...soapData.goals];
                                            updatedGoals[index].opportunities = e.target.value;
                                            onChange({ ...soapData, goals: updatedGoals });
                                        }}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4 mb-2">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Percent Accuracy</label>
                                    <input
                                        type="number"
                                        value={goal.percentAccuracy}
                                        onChange={(e) => {
                                            const updatedGoals = [...soapData.goals];
                                            updatedGoals[index].percentAccuracy = e.target.value;
                                            onChange({ ...soapData, goals: updatedGoals });
                                        }}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Level of Cueing</label>
                                    <input
                                        type="text"
                                        value={goal.cueingLevel}
                                        onChange={(e) => {
                                            const updatedGoals = [...soapData.goals];
                                            updatedGoals[index].cueingLevel = e.target.value;
                                            onChange({ ...soapData, goals: updatedGoals });
                                        }}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Notes</label>
                                <textarea
                                    value={goal.notes}
                                    onChange={(e) => {
                                        const updatedGoals = [...soapData.goals];
                                        updatedGoals[index].notes = e.target.value;
                                        onChange({ ...soapData, goals: updatedGoals });
                                    }}
                                    rows="3"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                ></textarea>
                            </div>
                            <button
                                type="button"
                                onClick={() => {
                                    const updatedGoals = soapData.goals.filter((_, i) => i !== index);
                                    onChange({ ...soapData, goals: updatedGoals });
                                }}
                                className="mt-2 text-red-600 hover:text-red-500"
                            >
                                Remove Goal
                            </button>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={() => {
                            const newGoal = { goal: '', opportunities: '', percentAccuracy: '', cueingLevel: '', notes: '' };
                            onChange({ ...soapData, goals: [...(soapData.goals || []), newGoal] });
                        }}
                        className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Add Goal
                    </button>
                </div>

                {/* Stuttering Severity Rating */}
                <div className="mb-6">
                    <h3 className="text-sm font-semibold leading-6 text-gray-900 mb-2">Stuttering Severity Rating</h3>
                    {soapData.stutteringSeverity && soapData.stutteringSeverity.map((rating, index) => (
                        <div key={index} className="mb-4 p-4 border border-gray-300 rounded-md">
                            <div className="grid grid-cols-2 gap-4 mb-2">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Rate: Syllables / minute</label>
                                    <input
                                        type="number"
                                        value={rating.rate}
                                        onChange={(e) => {
                                            const updatedRatings = [...soapData.stutteringSeverity];
                                            updatedRatings[index].rate = e.target.value;
                                            onChange({ ...soapData, stutteringSeverity: updatedRatings });
                                        }}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Task</label>
                                    <input
                                        type="text"
                                        value={rating.task}
                                        onChange={(e) => {
                                            const updatedRatings = [...soapData.stutteringSeverity];
                                            updatedRatings[index].task = e.target.value;
                                            onChange({ ...soapData, stutteringSeverity: updatedRatings });
                                        }}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-row gap-2 mb-2">
                                <div className="flex-1">
                                    <label className="block text-xs font-medium text-gray-700">EB</label>
                                    <input
                                        type="text"
                                        value={rating.eb || ''}
                                        onChange={(e) => {
                                            const updatedRatings = [...soapData.stutteringSeverity];
                                            updatedRatings[index].eb = e.target.value;
                                            onChange({ ...soapData, stutteringSeverity: updatedRatings });
                                        }}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs"
                                    />
                                </div>
                                <div className="flex-1">
                                    <label className="block text-xs font-medium text-gray-700">GS</label>
                                    <input
                                        type="text"
                                        value={rating.gs || ''}
                                        onChange={(e) => {
                                            const updatedRatings = [...soapData.stutteringSeverity];
                                            updatedRatings[index].gs = e.target.value;
                                            onChange({ ...soapData, stutteringSeverity: updatedRatings });
                                        }}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs"
                                    />
                                </div>
                                <div className="flex-1">
                                    <label className="block text-xs font-medium text-gray-700">LT</label>
                                    <input
                                        type="text"
                                        value={rating.lt || ''}
                                        onChange={(e) => {
                                            const updatedRatings = [...soapData.stutteringSeverity];
                                            updatedRatings[index].lt = e.target.value;
                                            onChange({ ...soapData, stutteringSeverity: updatedRatings });
                                        }}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs"
                                    />
                                </div>
                                <div className="flex-1">
                                    <label className="block text-xs font-medium text-gray-700">SB</label>
                                    <input
                                        type="text"
                                        value={rating.sb || ''}
                                        onChange={(e) => {
                                            const updatedRatings = [...soapData.stutteringSeverity];
                                            updatedRatings[index].sb = e.target.value;
                                            onChange({ ...soapData, stutteringSeverity: updatedRatings });
                                        }}
                                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs"
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Notes</label>
                                <textarea
                                    value={rating.notes}
                                    onChange={(e) => {
                                        const updatedRatings = [...soapData.stutteringSeverity];
                                        updatedRatings[index].notes = e.target.value;
                                        onChange({ ...soapData, stutteringSeverity: updatedRatings });
                                    }}
                                    rows="3"
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                ></textarea>
                            </div>
                            <button
                                type="button"
                                onClick={() => {
                                    const updatedRatings = soapData.stutteringSeverity.filter((_, i) => i !== index);
                                    onChange({ ...soapData, stutteringSeverity: updatedRatings });
                                }}
                                className="mt-2 text-red-600 hover:text-red-500"
                            >
                                Remove Stuttering Severity Rating
                            </button>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={() => {
                            const newRating = { rate: '', task: '', eb: '', gs: '', lt: '', sb: '', notes: '' };
                            onChange({ ...soapData, stutteringSeverity: [...(soapData.stutteringSeverity || []), newRating] });
                        }}
                        className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Add Stuttering Severity Rating
                    </button>
                </div>
                <div className="mt-6">
                    <h3 className="text-lg font-semibold leading-7 text-gray-900 mb-4">Additional Information</h3>
                    
                    <div className="mb-4">
                        <label htmlFor="functionalVocabulary" className="block text-sm font-medium text-gray-700">
                            Functional Vocabulary used/understood:
                        </label>
                        <input
                            type="text"
                            id="functionalVocabulary"
                            name="functionalVocabulary"
                            value={soapData.functionalVocabulary || ''}
                            onChange={(e) => onChange({ ...soapData, functionalVocabulary: e.target.value })}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="languageSample" className="block text-sm font-medium text-gray-700">
                            Language sample:
                        </label>
                        <input
                            type="text"
                            id="languageSample"
                            name="languageSample"
                            value={soapData.languageSample || ''}
                            onChange={(e) => onChange({ ...soapData, languageSample: e.target.value })}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="other" className="block text-sm font-medium text-gray-700">
                            Other:
                        </label>
                        <input
                            type="text"
                            id="other"
                            name="other"
                            value={soapData.other || ''}
                            onChange={(e) => onChange({ ...soapData, other: e.target.value })}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                </div>
            </div>
            
            <div className="mb-6">
                <h3 className="text-lg font-semibold leading-7 text-gray-900">Assessment</h3>
                <div className="mt-2">
                    <h4 className="text-md font-medium text-gray-700 mb-2">Progress Compared to Last Session:</h4>
                    <select
                        name="progressComparison"
                        id="progressComparison"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        value={soapData.progressComparison || ''}
                        onChange={(e) => onChange({ ...soapData, progressComparison: e.target.value })}
                    >
                        <option value="">Select an option</option>
                        <option value="steadyImprovement">Steady Improvement</option>
                        <option value="reducedAccuracy">Reduced Accuracy</option>
                        <option value="reducedTaskOrientation">Reduced Task Orientation</option>
                        <option value="similarToLastWeek">Similar to Last Week</option>
                    </select>
                </div>

                <div className="mt-4">
                    <h4 className="text-md font-medium text-gray-700 mb-2">Clinical Approach:</h4>
                    <div className="space-y-2">
                        {['Client Centered (Incorporating special interests)', 'Child Led', 'Clinician Directed', 'Neurodiversity Affirming'].map((approach) => (
                            <div key={approach} className="flex items-center">
                                <input
                                    type="checkbox"
                                    id={approach.replace(/\s+/g, '')}
                                    name="clinicalApproach"
                                    value={approach}
                                    checked={soapData.clinicalApproach?.includes(approach) || false}
                                    onChange={(e) => {
                                        const updatedApproaches = e.target.checked
                                            ? [...(soapData.clinicalApproach || []), approach]
                                            : (soapData.clinicalApproach || []).filter(a => a !== approach);
                                        onChange({ ...soapData, clinicalApproach: updatedApproaches });
                                    }}
                                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label htmlFor={approach.replace(/\s+/g, '')} className="ml-2 block text-sm text-gray-900">
                                    {approach}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mt-4">
                    <h4 className="text-md font-medium text-gray-700 mb-2">Articulation and Motor Speech:</h4>
                    <textarea
                        name="articulationMotorSpeech"
                        id="articulationMotorSpeech"
                        rows="4"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        value={soapData.articulationMotorSpeech || ''}
                        onChange={(e) => onChange({ ...soapData, articulationMotorSpeech: e.target.value })}
                        placeholder="Enter details about articulation and motor speech..."
                    ></textarea>
                </div>

                <div className="mt-4">
                    <h4 className="text-md font-medium text-gray-700 mb-2">Clinician Observations:</h4>
                    <div className="space-y-2">
                        {[
                            'Clinician sub-stepped (additional cueing)',
                            'Clinician super stepped, fading of cueing observed in session, progress seen',
                            'Minimal Trials due to reduced task orientation or other',
                            'Sufficient trials',
                            'Client benefited from Tactile cue, visual verbal cue needed to support Jaw Slide Jaw Jut Jaw grading'
                        ].map((observation) => (
                            <div key={observation} className="flex items-center">
                                <input
                                    type="checkbox"
                                    id={observation.replace(/\s+/g, '')}
                                    name="clinicianObservations"
                                    value={observation}
                                    checked={soapData.clinicianObservations?.includes(observation) || false}
                                    onChange={(e) => {
                                        const updatedObservations = e.target.checked
                                            ? [...(soapData.clinicianObservations || []), observation]
                                            : (soapData.clinicianObservations || []).filter(o => o !== observation);
                                        onChange({ ...soapData, clinicianObservations: updatedObservations });
                                    }}
                                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label htmlFor={observation.replace(/\s+/g, '')} className="ml-2 block text-sm text-gray-900">
                                    {observation}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mt-4">
                    <h4 className="text-md font-medium text-gray-700 mb-2">Communicative Intents Observed:</h4>
                    <div className="space-y-2">
                        {['Commenting', 'Requesting', 'Social Joy', 'Negation', 'Joint Attention'].map((intent) => (
                            <div key={intent} className="flex items-center">
                                <input
                                    type="checkbox"
                                    id={intent}
                                    name="communicativeIntents"
                                    value={intent}
                                    checked={soapData.communicativeIntents?.includes(intent) || false}
                                    onChange={(e) => {
                                        const updatedIntents = e.target.checked
                                            ? [...(soapData.communicativeIntents || []), intent]
                                            : (soapData.communicativeIntents || []).filter(i => i !== intent);
                                        onChange({ ...soapData, communicativeIntents: updatedIntents });
                                    }}
                                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label htmlFor={intent} className="ml-2 block text-sm text-gray-900">
                                    {intent}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mt-4">
                    <h4 className="text-md font-medium text-gray-700 mb-2">Play Observed:</h4>
                    <div className="space-y-2">
                        {['Parallel Play', 'Independent Play', 'Collaborative Play', 'Constructive Play', 'Pretend Play'].map((playType) => (
                            <div key={playType} className="flex items-center">
                                <input
                                    type="checkbox"
                                    id={playType.replace(/\s+/g, '')}
                                    name="playObserved"
                                    value={playType}
                                    checked={soapData.playObserved?.includes(playType) || false}
                                    onChange={(e) => {
                                        const updatedPlayTypes = e.target.checked
                                            ? [...(soapData.playObserved || []), playType]
                                            : (soapData.playObserved || []).filter(p => p !== playType);
                                        onChange({ ...soapData, playObserved: updatedPlayTypes });
                                    }}
                                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label htmlFor={playType.replace(/\s+/g, '')} className="ml-2 block text-sm text-gray-900">
                                    {playType}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mt-4">
                    <h4 className="text-md font-medium text-gray-700 mb-2">Early Language Strategies Observed:</h4>
                    <div className="space-y-2">
                        {[
                            'Reduced Questions, more commenting in Play',
                            'Too many Questions, not enough Comments',
                            'Followed child\'s lead (play and in conversation)',
                            'Benefited from model to follow child\'s lead',
                            'Equal turn taking',
                            'Effective use of play routines'
                        ].map((strategy) => (
                            <div key={strategy} className="flex items-center">
                                <input
                                    type="checkbox"
                                    id={strategy.replace(/\s+/g, '')}
                                    name="earlyLanguageStrategies"
                                    value={strategy}
                                    checked={soapData.earlyLanguageStrategies?.includes(strategy) || false}
                                    onChange={(e) => {
                                        const updatedStrategies = e.target.checked
                                            ? [...(soapData.earlyLanguageStrategies || []), strategy]
                                            : (soapData.earlyLanguageStrategies || []).filter(s => s !== strategy);
                                        onChange({ ...soapData, earlyLanguageStrategies: updatedStrategies });
                                    }}
                                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label htmlFor={strategy.replace(/\s+/g, '')} className="ml-2 block text-sm text-gray-900">
                                    {strategy}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mt-4">
                    <h4 className="text-md font-medium text-gray-700 mb-2">AAC:</h4>
                    <div className="space-y-2">
                        {['Direct selection observed', 'Navigating Folders observed', 'Aided Language Stimulation used'].map((aacOption) => (
                            <div key={aacOption} className="flex items-center">
                                <input
                                    type="checkbox"
                                    id={aacOption.replace(/\s+/g, '')}
                                    name="aacObservations"
                                    value={aacOption}
                                    checked={soapData.aacObservations?.includes(aacOption) || false}
                                    onChange={(e) => {
                                        const updatedAacObservations = e.target.checked
                                            ? [...(soapData.aacObservations || []), aacOption]
                                            : (soapData.aacObservations || []).filter(a => a !== aacOption);
                                        onChange({ ...soapData, aacObservations: updatedAacObservations });
                                    }}
                                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label htmlFor={aacOption.replace(/\s+/g, '')} className="ml-2 block text-sm text-gray-900">
                                    {aacOption}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mt-4">
                    <h4 className="text-md font-medium text-gray-700 mb-2">Processing Time and Attention:</h4>
                    <div className="flex items-center space-x-4">
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                id="increasedProcessingTime"
                                name="processingTime"
                                checked={soapData.increasedProcessingTime || false}
                                onChange={(e) => onChange({ ...soapData, increasedProcessingTime: e.target.checked })}
                                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            />
                            <label htmlFor="increasedProcessingTime" className="ml-2 block text-sm text-gray-900">
                                ++Processing Time
                            </label>
                        </div>
                        {soapData.increasedProcessingTime && (
                            <input
                                type="text"
                                id="processingTimeValue"
                                name="processingTimeValue"
                                value={soapData.processingTimeValue || ''}
                                onChange={(e) => onChange({ ...soapData, processingTimeValue: e.target.value })}
                                placeholder="Time"
                                className="mt-1 block w-24 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        )}
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                id="noConcern"
                                name="processingTime"
                                checked={soapData.noConcern || false}
                                onChange={(e) => onChange({ ...soapData, noConcern: e.target.checked })}
                                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            />
                            <label htmlFor="noConcern" className="ml-2 block text-sm text-gray-900">
                                No Concern
                            </label>
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <h4 className="text-md font-medium text-gray-700 mb-2">Fluency:</h4>
                    <textarea
                        name="fluency"
                        id="fluency"
                        rows="4"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        value={soapData.fluency || ''}
                        onChange={(e) => onChange({ ...soapData, fluency: e.target.value })}
                        placeholder="Stability observed at slow stretch medium stretch slight stretch control. Client demonstrated consistent use of fluency shaping skills verbal and visual cueing to remain in stretch."
                    ></textarea>
                </div>

                <div className="mt-4">
                    <h4 className="text-md font-medium text-gray-700 mb-2">Feedback:</h4>
                    <div className="space-y-2">
                        <div>
                            <label htmlFor="easyBreathing" className="block text-sm font-medium text-gray-700">1. Feedback on Easy Breathing:</label>
                            <select
                                id="easyBreathing"
                                name="easyBreathing"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={soapData.easyBreathing || ''}
                                onChange={(e) => onChange({ ...soapData, easyBreathing: e.target.value })}
                            >
                                <option value="">Select an option</option>
                                <option value="accurate">Accurate</option>
                                <option value="shallow">Shallow</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="gentleStarts" className="block text-sm font-medium text-gray-700">2. Feedback on Gentle Starts:</label>
                            <input
                                type="text"
                                id="gentleStarts"
                                name="gentleStarts"
                                value={soapData.gentleStarts || ''}
                                onChange={(e) => onChange({ ...soapData, gentleStarts: e.target.value })}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                        </div>
                        <div>
                            <label htmlFor="smoothBlending" className="block text-sm font-medium text-gray-700">3. Feedback on Smooth Blending:</label>
                            <select
                                id="smoothBlending"
                                name="smoothBlending"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={soapData.smoothBlending || ''}
                                onChange={(e) => onChange({ ...soapData, smoothBlending: e.target.value })}
                            >
                                <option value="">Select an option</option>
                                <option value="accurate">Accurate</option>
                                <option value="equalStress">Equal stress</option>
                                <option value="monotonous">Monotonous</option>
                                <option value="inconsistent">Inconsistent</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="lightTouches" className="block text-sm font-medium text-gray-700">4. Feedback on Light touches:</label>
                            <select
                                id="lightTouches"
                                name="lightTouches"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={soapData.lightTouches || ''}
                                onChange={(e) => onChange({ ...soapData, lightTouches: e.target.value })}
                            >
                                <option value="">Select an option</option>
                                <option value="accurate">Accurate</option>
                                <option value="tooLight">Too light</option>
                                <option value="tooHard">Too hard</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="selfCorrections" className="block text-sm font-medium text-gray-700">5. Feedback on Self Corrections:</label>
                            <select
                                id="selfCorrections"
                                name="selfCorrections"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                value={soapData.selfCorrections || ''}
                                onChange={(e) => onChange({ ...soapData, selfCorrections: e.target.value })}
                            >
                                <option value="">Select an option</option>
                                <option value="accuratePullOut">Accurate pull out</option>
                                <option value="accurateReBreathe">Accurate re-breathe</option>
                                <option value="benefitedFromModelAndCue">Benefited from model and cue</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <label htmlFor="otherAssessment" className="block text-sm font-medium text-gray-700">Other:</label>
                    <textarea
                        id="otherAssessment"
                        name="otherAssessment"
                        rows="3"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        value={soapData.otherAssessment || ''}
                        onChange={(e) => onChange({ ...soapData, otherAssessment: e.target.value })}
                    ></textarea>
                </div>
            </div>
            <div className="mt-4">
                <label htmlFor="plan" className="block text-sm font-medium text-gray-700">Plan:</label>
                <div className="mt-1">
                    <div className="flex flex-col space-y-2">
                        <label className="inline-flex items-center">
                            <input
                                type="checkbox"
                                className="form-checkbox h-5 w-5 text-indigo-600"
                                checked={soapData.planContinueTargetingGoals || false}
                                onChange={(e) => onChange({ ...soapData, planContinueTargetingGoals: e.target.checked })}
                            />
                            <span className="ml-2">Continue targeting goals</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input
                                type="checkbox"
                                className="form-checkbox h-5 w-5 text-indigo-600"
                                checked={soapData.planShareProgressReport || false}
                                onChange={(e) => onChange({ ...soapData, planShareProgressReport: e.target.checked })}
                            />
                            <span className="ml-2">Share progress report due next week</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input
                                type="checkbox"
                                className="form-checkbox h-5 w-5 text-indigo-600"
                                checked={soapData.planSendHomework || false}
                                onChange={(e) => onChange({ ...soapData, planSendHomework: e.target.checked })}
                            />
                            <span className="ml-2">Send homework</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input
                                type="checkbox"
                                className="form-checkbox h-5 w-5 text-indigo-600"
                                checked={soapData.planCurateHomeProgram || false}
                                onChange={(e) => onChange({ ...soapData, planCurateHomeProgram: e.target.checked })}
                            />
                            <span className="ml-2">Curate home program</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input
                                type="checkbox"
                                className="form-checkbox h-5 w-5 text-indigo-600"
                                checked={soapData.planUpdateSchedule || false}
                                onChange={(e) => onChange({ ...soapData, planUpdateSchedule: e.target.checked })}
                            />
                            <span className="ml-2">Update schedule/bookings accordingly</span>
                        </label>
                    </div>
                </div>
                <textarea
                    id="planNotes"
                    name="planNotes"
                    rows="3"
                    className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={soapData.planNotes || ''}
                    onChange={(e) => onChange({ ...soapData, planNotes: e.target.value })}
                    placeholder="Additional plan notes..."
                ></textarea>
            </div>
            {renderField("Additional Notes", "additionalNotes", "textarea")}
        </div>
    );
};

export default SOAPTemplate;
