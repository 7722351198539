import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { modifyUser, createUser } from "../../../store/thunks/userThunk";
import GoogleMapsAutocomplete from "../../../applicationUi/components/GoogleAutoComplete";
import { generateRandomPassword } from "../../../utils/generateRandomPassword";

const UserPersonalInformation = ({ user, onComplete, isEditMode = false }) => {
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);

  console.log("user in userpersonalinformation", user);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      phoneNumbers: user?.phoneNumbers || [{ type: "", number: "" }],
      dateOfBirth: user?.dateOfBirth
        ? new Date(user.dateOfBirth).toISOString().split("T")[0]
        : "",
      address: {
        street: user?.address?.street || "",
        city: user?.address?.city || "",
        province: user?.address?.province || "",
        postalCode: user?.address?.postalCode || "",
        country: user?.address?.country || "",
      },
      password: "",
      employeeId: user?.employeeId || "",
    },

    validationSchema: Yup.object({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      phoneNumbers: Yup.array().of(
        Yup.object({
          type: Yup.string(),
          number: Yup.string(),
        })
      ),
      dateOfBirth: Yup.date(),
      password: isEditMode ? Yup.string() : Yup.string().required("Required"),
      employeeId: isEditMode ? Yup.string() : Yup.string().required("Required"),
    }),

    onSubmit: async (values) => {
      setIsSaving(true);
      setIsSaved(false);
      console.log("tryign to create user now");
      let userData = values;
      if (isEditMode) {
        const getChangedFields = (values, original) => {
          const changedFields = {};

          for (const key in values) {
            if (key === "address") {
              // Check if any field within the address object has changed
              const addressChanges = {};
              for (const addressKey in values.address) {
                if (
                  values.address[addressKey] !==
                  (original.address || {})[addressKey]
                ) {
                  addressChanges[addressKey] = values.address[addressKey];
                }
              }
              // If there are any changes in the address, include the full address object
              if (Object.keys(addressChanges).length > 0) {
                changedFields.address = {
                  ...original.address,
                  ...addressChanges,
                };
              }
            } else if (
              JSON.stringify(values[key]) !== JSON.stringify(original[key]) &&
              values[key] !== ""
            ) {
              changedFields[key] = values[key];
            }
          }

          return changedFields;
        };
        console.log("after getting changed fields");

        userData = getChangedFields(values, user);
      }

      console.log("userDAta:", userData);

      // Remove empty password field if in edit mode
      if (isEditMode && !userData.password) {
        delete userData.password;
      }

      console.log(
        `${isEditMode ? "Modifying" : "Creating"} user with data:`,
        userData
      );

      const action = isEditMode
        ? modifyUser({ userId: user._id, userData })
        : createUser(userData);
      await dispatch(action);
      setIsSaving(false);
      setIsSaved(true);
    },
  });

  const handleGenerateNewPassword = () => {
    const newPassword = generateRandomPassword();
    formik.setFieldValue("password", newPassword);
    console.log("New password generated and set in form");
  };

  const handlePlaceSelected = (place) => {
    const addressComponents = place.address_components;
    let street = "";
    let city = "";
    let province = "";
    let postalCode = "";
    let country = "";

    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes("street_number")) {
        street = component.long_name + " ";
      }
      if (types.includes("route")) {
        street += component.long_name;
      }
      if (types.includes("locality")) {
        city = component.long_name;
      }
      if (types.includes("administrative_area_level_1")) {
        province = component.long_name;
      }
      if (types.includes("postal_code")) {
        postalCode = component.long_name;
      }
      if (types.includes("country")) {
        country = component.long_name;
      }
    });

    formik.setFieldValue("address", {
      street,
      city,
      province,
      postalCode,
      country,
    });

    console.log("set this postal code:", postalCode);
  };

  const handleAddPhoneNumber = () => {
    formik.setFieldValue("phoneNumbers", [
      ...formik.values.phoneNumbers,
      { type: "", number: "" },
    ]);
  };

  const handleRemovePhoneNumber = (index) => {
    const newPhoneNumbers = [...formik.values.phoneNumbers];
    newPhoneNumbers.splice(index, 1);
    formik.setFieldValue("phoneNumbers", newPhoneNumbers);
  };

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-6">
      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <label
            htmlFor="firstName"
            className="block text-sm font-medium text-gray-700">
            First name
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="firstName"
              id="firstName"
              autoComplete="off"
              {...formik.getFieldProps("firstName")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          {formik.touched.firstName && formik.errors.firstName ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.firstName}
            </div>
          ) : null}
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="lastName"
            className="block text-sm font-medium text-gray-700">
            Last name
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="lastName"
              id="lastName"
              autoComplete="off"
              {...formik.getFieldProps("lastName")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          {formik.touched.lastName && formik.errors.lastName ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.lastName}
            </div>
          ) : null}
        </div>

        <div className="sm:col-span-4">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700">
            Email address
          </label>
          <div className="mt-1 flex items-center space-x-2">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="off"
              {...formik.getFieldProps("email")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
            {/* {!isEditMode && ( */}
            <button
              type="button"
              onClick={handleGenerateNewPassword}
              className="text-sm px-2.5 py-1.5 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap w-48 flex items-center justify-center mx-auto">
              Generate New Password
            </button>
            {/* )} */}
          </div>
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.email}
            </div>
          ) : null}
        </div>

        {formik.values.password && (
          <div className="sm:col-span-4 mt-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700">
              New Password
            </label>
            <div className="mt-1 flex items-center space-x-2">
              <input
                type="text"
                id="password"
                name="password"
                {...formik.getFieldProps("password")}
                readOnly
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
        )}

        <div className="sm:col-span-4 mt-4">
          <label
            htmlFor="employeeId"
            className="block text-sm font-medium text-gray-700">
            EmployeeId
          </label>
          <div className="mt-1 flex items-center space-x-2">
            <input
              type="text"
              id="employeeId"
              name="employeeId"
              {...formik.getFieldProps("employeeId")}
              readOnly={isEditMode}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>

        <div className="sm:col-span-6">
          <label className="block text-sm font-medium text-gray-700">
            Phone Numbers
          </label>
          {formik.values.phoneNumbers &&
            formik.values.phoneNumbers.map((phone, index) => (
              <div key={index} className="flex items-center space-x-2 mt-1">
                <select
                  name={`phoneNumbers[${index}].type`}
                  value={phone.type}
                  onChange={formik.handleChange}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-1/4 sm:text-sm border-gray-300 rounded-md">
                  <option value="">Select Type</option>
                  <option value="Home">Home</option>
                  <option value="Work">Work</option>
                  <option value="Mobile">Mobile</option>
                  <option value="Other">Other</option>
                </select>
                <input
                  type="text"
                  name={`phoneNumbers[${index}].number`}
                  value={phone.number}
                  onChange={formik.handleChange}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-2/4 sm:text-sm border-gray-300 rounded-md"
                  placeholder="Phone number"
                />
                <button
                  type="button"
                  onClick={() => handleRemovePhoneNumber(index)}
                  className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                  Remove
                </button>
              </div>
            ))}
          <button
            type="button"
            onClick={handleAddPhoneNumber}
            className="mt-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Add Phone Number
          </button>
          {formik.touched.phoneNumbers && formik.errors.phoneNumbers ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.phoneNumbers}
            </div>
          ) : null}
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="dateOfBirth"
            className="block text-sm font-medium text-gray-700">
            Date of Birth
          </label>
          <div className="mt-1">
            <input
              type="date"
              name="dateOfBirth"
              id="dateOfBirth"
              autoComplete="off"
              {...formik.getFieldProps("dateOfBirth")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          {formik.touched.dateOfBirth && formik.errors.dateOfBirth ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.dateOfBirth}
            </div>
          ) : null}
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="address"
            className="block text-sm font-medium text-gray-700">
            Address
          </label>
          <div className="mt-1">
            <GoogleMapsAutocomplete
              onPlaceSelected={handlePlaceSelected}
              inputId="address"
            />
          </div>
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="street"
            className="block text-sm font-medium text-gray-700">
            Street address
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="street"
              id="street"
              autoComplete="off"
              {...formik.getFieldProps("address.street")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          {formik.touched.street && formik.errors.street ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.street}
            </div>
          ) : null}
        </div>

        <div className="sm:col-span-2">
          <label
            htmlFor="city"
            className="block text-sm font-medium text-gray-700">
            City
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="city"
              id="city"
              autoComplete="off"
              {...formik.getFieldProps("address.city")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          {formik.touched.city && formik.errors.city ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.city}
            </div>
          ) : null}
        </div>

        <div className="sm:col-span-2">
          <label
            htmlFor="province"
            className="block text-sm font-medium text-gray-700">
            Province
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="province"
              id="province"
              autoComplete="off"
              {...formik.getFieldProps("address.province")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          {formik.touched.province && formik.errors.province ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.province}
            </div>
          ) : null}
        </div>

        <div className="sm:col-span-2">
          <label
            htmlFor="postalCode"
            className="block text-sm font-medium text-gray-700">
            Postal code
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="postalCode"
              id="postalCode"
              autoComplete="off"
              {...formik.getFieldProps("address.postalCode")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          {formik.touched.postalCode && formik.errors.postalCode ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.postalCode}
            </div>
          ) : null}
        </div>

        <div className="sm:col-span-3">
          <label
            htmlFor="country"
            className="block text-sm font-medium text-gray-700">
            Country
          </label>
          <div className="mt-1">
            <select
              id="country"
              name="country"
              autoComplete="off"
              {...formik.getFieldProps("address.country")}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
              <option value="">Select a country</option>
              <option value="United States">United States</option>
              <option value="Canada">Canada</option>
              <option value="Mexico">Mexico</option>
            </select>
          </div>
          {formik.touched.country && formik.errors.country ? (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.country}
            </div>
          ) : null}
        </div>
      </div>

      <div className="pt-5 flex justify-end">
        <button
          type="submit"
          disabled={isSaving}
          className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
            isSaving || isSaved
              ? "bg-green-600 hover:bg-green-700"
              : "bg-indigo-600 hover:bg-indigo-700"
          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}>
          {isSaving 
            ? "Saving..." 
            : isSaved
            ? "Saved!"
            : isEditMode 
            ? "Save Changes" 
            : "Create User"}
        </button>
      </div>
    </form>
  );
};

export default UserPersonalInformation;
