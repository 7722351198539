import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAppointmentsByDateRange } from "../../store/thunks/appointmentsThunk";
import { fetchAllPayrollsByStatusAndPayPeriod } from "../../store/thunks/payrollThunk";
import { fetchClinicians } from "../../store/thunks/cliniciansThunk";
import HRIndividualPayrollPage from "./HRIndividualPayrollPage";
import PayrollScheduleTable from "./PayrollSchedule";
import PayrollSlideOver from "./PayrollSlideOver";
import payrollDates from "./payrollDates";
import PayrollStats from "./PayrollStats";
import { Listbox } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

export default function HRPayrollOverviewPage() {
  const dispatch = useDispatch();
  const clinicians = useSelector((state) => state.clinicians.clinicians || []);
  const [isPayrollSlideOver, setIsPayrollSlideOver] = useState(false);
  const [ishrpayrollpage, setishrpayrollpage] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showPayrollSchedule, setShowPayrollSchedule] = useState(false);
  const [selectedClinician, setSelectedClinician] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [submissionDate, setSubmissionDate] = useState(new Date());

  const allAppointments = useSelector(
    (state) => state.appointments.allPayrollAppointments || []
  );
  const allPayrolls = useSelector(
    (state) => state.payrolls.allCliniciansPayrolls || []
  );

  console.log("allappts", allAppointments);
  console.log("allpayrolls", allPayrolls);

  const findCurrentPayPeriod = () => {
    let closestPeriod = payrollDates[0];
    let minDiff = Number.MAX_VALUE;

    payrollDates.forEach((period) => {
      const [startDateString, endDateString] = period.includeDates.split("-");
      const startDate = new Date(Date.parse(`${startDateString.trim()} 2024`));
      const endDate = new Date(Date.parse(`${endDateString.trim()} 2024`));
      endDate.setHours(23, 59, 59, 999);

      if (currentDate >= startDate && currentDate <= endDate) {
        closestPeriod = period;
        minDiff = 0;
      } else {
        const diff = Math.min(
          Math.abs(currentDate - startDate),
          Math.abs(currentDate - endDate)
        );
        if (diff < minDiff) {
          closestPeriod = period;
          minDiff = diff;
        }
      }
    });

    return closestPeriod;
  };

  const [selectedPeriod, setSelectedPeriod] = useState(findCurrentPayPeriod());

  const handlePeriodChange = (selectedPeriod) => {
    setStartDate(
      new Date(`${selectedPeriod.includeDates.split("-")[0].trim()} 2024`)
    );
    setEndDate(
      new Date(`${selectedPeriod.includeDates.split("-")[1].trim()} 2024`)
    );

    setSelectedPeriod(selectedPeriod);
    setSubmissionDate(new Date(`${selectedPeriod.submissionDate}`));

    console.log(
      "RECEIVED THIS SELECTED PERIOD IN THE NEW HADNLER",
      selectedPeriod
    );
  };

  // console.log("selected period is", selectedPeriod);
  // console.log("startdate is:", startDate);
  // console.log("enddate is:", endDate);

  console.log(
    "before the useeffect, startdate is",
    startDate,
    "enddate is",
    endDate
  );

  const [inCarryover, setInCarryover] = useState(false);

  useEffect(() => {
    if (selectedPeriod) {
      try {
        dispatch(fetchClinicians());

        dispatch(fetchAllAppointmentsByDateRange({ endDate }));

        dispatch(
          fetchAllPayrollsByStatusAndPayPeriod({
            statuses: ["Pending", "Ready", "Paid to Clinician"],
            payPeriod: selectedPeriod.payPeriodNumber,
          })
        );

        setInCarryover(currentDate > submissionDate);
      } catch (error) {
        console.error("Fetch data error:", error);
      }
    }
  }, [
    dispatch,
    startDate,
    endDate,
    selectedPeriod,
    currentDate,
    submissionDate,
  ]);

  const handleClinicianClick = (clinician) => {
    setSelectedClinician(clinician);
    setishrpayrollpage(true);
  };

  const handleCloseModal = () => {
    setShowPayrollSchedule(false);
  };

  const getBorderStyle = (data) => {
    const hasPendingAppointments = (data.pendingAppointments || []).length > 0;
    const hasReadyCompletedAppointments = (
      data.completedAppointments || []
    ).some((appointment) => appointment.status === "Ready");
    const hasPaidCompletedAppointments = (
      data.completedAppointments || []
    ).some((appointment) => appointment.status === "Paid to Clinician");

    if (hasReadyCompletedAppointments) {
      return "border-green-500 border-dashed";
    } else if (hasPaidCompletedAppointments) {
      return "border-green-500 border-solid";
    } else if (hasPendingAppointments) {
      return "border-yellow-500 border-dashed";
    } else {
      return "border-yellow-500 border-solid";
    }
  };

  const getSubmissionDate = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const submissionDate = new Date(currentPeriod.submissionDate);
      return submissionDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return "N/A";
    }
  };

  const getScheduledPayDay = () => {
    const currentPeriod = payrollDates.find(
      (period) => period.payPeriodNumber === selectedPeriod.payPeriodNumber
    );

    if (currentPeriod) {
      const paymentDate = new Date(currentPeriod.paymentDate);
      return paymentDate.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      });
    } else {
      return "N/A";
    }
  };

  const calculatePendingAppointments = () => {
    if (!allAppointments || typeof allAppointments !== "object" || inCarryover)
      return 0;

    const totalPendingAppointments = Object.values(allAppointments).reduce(
      (total, clinicianData) => {
        const { appointments } = clinicianData;

        const clinicianPendingCount = appointments.filter((appointment) => {
          const hasSoapNotesComplete = appointment.history?.some(
            (historyItem) => historyItem.status === "Soap Notes Complete"
          );
          const hasInvoicePaid =
            appointment.invoice && appointment.invoice.status === "paid";

          return !(hasSoapNotesComplete && hasInvoicePaid);
        }).length;

        return total + clinicianPendingCount;
      },
      0
    );

    return totalPendingAppointments;
  };

  const calculateCompletedAppointments = (clinicianId = null) => {
    if (!Array.isArray(allPayrolls)) return 0;

    return allPayrolls.filter((payroll) => {
      const isPaid = payroll.invoiceId?.status === "paid";
      const isMatchingClinician = clinicianId
        ? payroll.clinicianId?._id === clinicianId
        : true;

      return isPaid && isMatchingClinician;
    }).length;
  };
  const calculateCarryoverAppointments = () => {
    if (!inCarryover || typeof allAppointments !== "object") return 0;

    return Object.values(allAppointments).reduce(
      (total, clinicianData) => total + clinicianData.appointments.length,
      0
    );
  };

  const calculatePendingPay = () => {
    if (!allAppointments || typeof allAppointments !== "object" || inCarryover)
      return 0;

    const totalPendingPay = Object.values(allAppointments).reduce(
      (total, clinicianData) => {
        const { appointments, clinician } = clinicianData;
        const payPercentage = clinician.defaultPayPercentage
          ? clinician.defaultPayPercentage / 100
          : 0;

        const clinicianTotal = appointments.reduce((sum, appointment) => {
          if (appointment.invoice && appointment.invoice.amount) {
            const amount = appointment.invoice.amount;
            return sum + amount * payPercentage;
          }
          return sum;
        }, 0);

        return total + clinicianTotal;
      },
      0
    );

    return totalPendingPay.toFixed(2);
  };

  const calculateCompletedPay = () => {
    if (!Array.isArray(allPayrolls)) return 0;

    return allPayrolls
      .reduce((sum, payroll) => sum + (payroll.payrollAmount || 0), 0)
      .toFixed(2);
  };

  const calculateCarryoverPay = () => {
    if (!inCarryover || typeof allAppointments !== "object") return 0;

    const totalCarryoverPay = Object.values(allAppointments).reduce(
      (total, clinicianData) => {
        const { appointments, clinician } = clinicianData;
        const payPercentage = clinician.defaultPayPercentage
          ? clinician.defaultPayPercentage / 100
          : 0;

        const clinicianTotal = appointments.reduce((sum, appointment) => {
          if (appointment.invoice && appointment.invoice.amount) {
            const amount = appointment.invoice.amount;
            return sum + amount * payPercentage;
          }
          return sum;
        }, 0);

        return total + clinicianTotal;
      },
      0
    );

    return totalCarryoverPay.toFixed(2);
  };

  const stats = [
    {
      name: `Pay Period ${selectedPeriod.payPeriodNumber}`,
      value: `${selectedPeriod.includeDates}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Submit Appointments By",
      value: `${getSubmissionDate()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Pay Day",
      value: `${getScheduledPayDay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Pending Appointments",
      value: `${calculatePendingAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Completed Appointments",
      value: `${calculateCompletedAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Carryover Appointments",
      value: `${calculateCarryoverAppointments()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Total Pending Pay",
      value: `$${calculatePendingPay()}`,
      change: "",
      changeType: "neutral",
      extra: "(Default 45%)",
    },
    {
      name: "Total Completed Pay",
      value: `$${calculateCompletedPay()}`,
      change: "",
      changeType: "neutral",
    },
    {
      name: "Total Carryover Pay",
      value: `$${calculateCarryoverPay()}`,
      change: "",
      changeType: "neutral",
    },
  ];

  return (
    <>
      <div className="py-4 pb-20">
        <PayrollStats stats={stats} />
        {/* Pay period selector and toggles */}
        <div className="flex space-x-4 mb-4 justify-around items-center">
          <div className="flex flex-col items-center space-y-2">
            <Listbox value={selectedPeriod} onChange={handlePeriodChange}>
              <div className="flex flex-col items-center">
                <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 text-center">
                  Select Payroll Period
                </Listbox.Label>
                <div className="relative mt-2 w-full">
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <span className="block truncate">
                      {`Pay Period ${selectedPeriod.payPeriodNumber} (${selectedPeriod.includeDates})`}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {payrollDates.map((period) => (
                      <Listbox.Option
                        key={period.payPeriodNumber}
                        value={period}
                        className={({ active, selected }) =>
                          `relative cursor-default select-none py-2 pl-3 pr-9 ${
                            active
                              ? "bg-indigo-600 text-white"
                              : "text-gray-900"
                          }`
                        }>
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-semibold" : "font-normal"
                              }`}>
                              {`Pay Period ${period.payPeriodNumber} (${period.includeDates})`}
                            </span>
                            {selected && (
                              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </div>
            </Listbox>
          </div>

          <div>
            <button
              type="button"
              onClick={() => setShowPayrollSchedule(!showPayrollSchedule)}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              View Payroll Schedule
            </button>
          </div>
        </div>

        {/* Clinician grid tiles */}
        <div>
          <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
            {allAppointments && Object.keys(allAppointments).length > 0 ? (
              Object.values(allAppointments).map(
                ({ clinician, appointments }) => (
                  <li
                    key={clinician._id}
                    className={`overflow-hidden rounded-xl border ${getBorderStyle(
                      {
                        pendingAppointments: appointments.filter(
                          (app) => !app.invoice || app.invoice.status !== "paid"
                        ),
                        completedAppointments: appointments.filter(
                          (app) => app.invoice && app.invoice.status === "paid"
                        ),
                      }
                    )}`}
                    onClick={() => handleClinicianClick(clinician)}>
                    <div className="flex items-center gap-x-2 border-b border-gray-900/5 bg-gray-50 p-4">
                      <div className="text-sm font-medium leading-6 text-gray-900">
                        {clinician.firstName} {clinician.lastName}
                      </div>
                    </div>
                    <dl className="-my-3 divide-y divide-gray-100 px-4 py-4 text-sm leading-6">
                      {inCarryover ? (
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Carryover Appts</dt>
                          <dd className="font-medium text-gray-900">
                            {appointments.length}
                          </dd>
                        </div>
                      ) : (
                        <div className="flex justify-between gap-x-4 py-3">
                          <dt className="text-gray-500">Pending Appts</dt>
                          <dd className="font-medium text-gray-900">
                            {appointments.length}
                          </dd>
                        </div>
                      )}
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Completed Appts</dt>
                        <dd className="font-medium text-gray-900">
                          {/* {
                            appointments.filter(
                              (app) =>
                                app.invoice && app.invoice.status === "paid"
                            ).length
                          } */}
                          {calculateCompletedAppointments(clinician._id)}
                        </dd>
                      </div>
                    </dl>
                  </li>
                )
              )
            ) : (
              <div className="text-center text-gray-500">
                No appointments available.
              </div>
            )}
          </ul>
        </div>

        {/* <div>
          <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
            {Array.isArray(allAppointments) && allAppointments.length > 0 ? (
              allAppointments.map((data) => (
                <li
                  key={data.clinicianId}
                  className={`overflow-hidden rounded-xl border ${getBorderStyle(
                    data
                  )}`}
                  onClick={() => handleClinicianClick(data)}>
                  <div className="flex items-center gap-x-2 border-b border-gray-900/5 bg-gray-50 p-4">
                    <div className="text-sm font-medium leading-6 text-gray-900">
                      {data.firstName} {data.lastName}
                    </div>
                  </div>
                  <dl className="-my-3 divide-y divide-gray-100 px-4 py-4 text-sm leading-6">
                    <div className="flex justify-between gap-x-4 py-3">
                      <dt className="text-gray-500">Pending Appts</dt>
                      <dd className="font-medium text-gray-900">
                        {(data.pendingAppointments || []).length}
                      </dd>
                    </div>
                    <div className="flex justify-between gap-x-4 py-3">
                      <dt className="text-gray-500">Completed Appts</dt>
                      <dd className="font-medium text-gray-900">
                        {(data.completedAppointments || []).length}
                      </dd>
                    </div>
                    <div className="flex justify-between gap-x-4 py-3">
                      <dt className="text-gray-500">Carryover Appts</dt>
                      <dd className="font-medium text-gray-900">
                        {(data.carryoverAppointments || []).length}
                      </dd>
                    </div>
                  </dl>
                </li>
              ))
            ) : (
              <div className="text-center text-gray-500">
                No appointments available.
              </div>
            )}
          </ul>
        </div> */}
      </div>

      {showPayrollSchedule && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={handleCloseModal}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Escape") handleCloseModal();
          }}>
          <div
            className="bg-white rounded-lg shadow-lg p-8 max-w-4xl w-full relative"
            onClick={(e) => e.stopPropagation()}>
            <button
              onClick={handleCloseModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
              <XMarkIcon className="h-6 w-6" />
            </button>
            <PayrollScheduleTable />
          </div>
        </div>
      )}

      {ishrpayrollpage && selectedClinician && (
        <HRIndividualPayrollPage
          clinician={selectedClinician}
          selectedPeriod={selectedPeriod}
          isOpen={ishrpayrollpage}
          onClose={() => {
            setishrpayrollpage(false);
          }}
        />
      )}

      {isPayrollSlideOver && selectedAppointment && (
        <PayrollSlideOver
          isOpen={isPayrollSlideOver}
          appointment={selectedAppointment}
          onClose={() => setIsPayrollSlideOver(false)}
          selectedPeriod={selectedPeriod.payPeriodNumber}
          selectedPeriodDates={selectedPeriod.includeDates}
          isHr={true}
        />
      )}
    </>
  );
}
