import React, { useEffect, useState } from "react";
import Table from "../../applicationUi/components/Table"; // Adjust the path as necessary
import ClientUploads from "./ClientUploads"; // Adjust the path as necessary
import ClientDetails from "./ClientDetails";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { calculateAge } from "../../utils/calculateAge";
import { UsersIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import FamilyDetails from "./FamilyDetails";
import { useDispatch, useSelector } from "react-redux";
import { fetchClientById } from "../../store/thunks/clientsThunk";
import { uploadFile, fetchUploads } from "../../store/thunks/uploadsThunk";
import { logInfo, logError } from "../../utils/logger"; // Import logging functions
import {
  fetchTreatingClinician,
  fetchSupervisingClinician,
} from "../../store/store";
import ClientInvoices from "./ClientInvoices";
import CircularButton from "../../applicationUi/components/CircularButton";
// import ClientNotes from "../../applicationUi/components/ClientNotesModal";
import ClientNotes from "./ClientNotes";
import { clearSelectedClinicians } from "../../store/slices/cliniciansSlice";
import { clearSelectedClient } from "../../store/slices/clientsSlice";

export default function ClientOverview() {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("details");
  const [folderName, setFolderName] = useState("");
  const [password, setPassword] = useState("");
  const [newFolder, setNewFolder] = useState(false);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [existingFolders, setExistingFolders] = useState([]);
  const [file, setFile] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const treatingClinician = useSelector(
    (state) => state.clinicians.selectedTreatingClinician
  );
  const supervisingClinician = useSelector(
    (state) => state.clinicians.selectedSupervisingClinician
  );
  const client = useSelector((state) => state.clients.selectedClient);

  useEffect(() => {
    if (client?.treatingClinician?.current) {
      dispatch(fetchTreatingClinician(client?.treatingClinician?.current));
    }
    if (client?.supervisingClinician?.current) {
      dispatch(
        fetchSupervisingClinician(client?.supervisingClinician?.current)
      );
    }

    return () => {
      dispatch(clearSelectedClinicians());
      dispatch(clearSelectedClient());
    };
  }, [client, dispatch]);

  const clientAge = client?.dateOfBirth
    ? calculateAge(client.dateOfBirth)
    : "N/A";

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    logInfo("Tab changed", { newTab: tab });
  };

  const userId = useSelector((state) => state.auth.user._id);

  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (file && folderName) {
      try {
        const formData = new FormData();
        formData.append("document", file);
        formData.append("folderName", folderName);
        if (password) {
          formData.append("password", password);
        }

        const resultAction = await dispatch(
          uploadFile({
            clientId: client?._id ?? "",
            formData,
            folderName,
            userId: userId,
          })
        );

        if (uploadFile.fulfilled.match(resultAction)) {
          setUploadResponse({
            message: "File uploaded successfully",
            type: "success",
          });
          logInfo("File uploaded successfully", {
            clientId: client?._id,
            folderName,
          });
          // Clear the form
          setFile(null);
          setFolderName("");
          setPassword("");
        } else if (uploadFile.rejected.match(resultAction)) {
          setUploadResponse({
            message: resultAction.payload || "File upload failed",
            type: "error",
          });
          logError("File upload failed", {
            clientId: client?._id,
            folderName,
            error: resultAction.payload,
          });
        }
      } catch (error) {
        console.error("File upload failed", error);
        setUploadResponse({
          message: "An unexpected error occurred",
          type: "error",
        });
        logError("Unexpected error during file upload", {
          clientId: client?._id,
          folderName,
          error: error.message,
        });
      }
    } else {
      setUploadResponse({
        message: "Please provide both a file and a folder name",
        type: "error",
      });
      logError("File upload attempted without file or folder name", {
        clientId: client?._id,
      });
    }
  };

  const handleInvoiceClick = (invoice) => {
    console.log("Invoice clicked", invoice);
  };

  const handleSoapNoteClick = (soapNote) => {
    console.log("Soap note clicked", soapNote);
  };

  useEffect(() => {
    if (client?._id) {
      dispatch(fetchUploads(client._id))
        .then((resultAction) => {
          if (fetchUploads.fulfilled.match(resultAction)) {
            const files = resultAction.payload;
            if (files && Array.isArray(files)) {
              // Extract folder names from file paths
              const folderSet = new Set();
              files.forEach((filePath) => {
                const folderName = filePath.split("/")[0];
                folderSet.add(folderName);
              });
              setExistingFolders(Array.from(folderSet)); // Update the state here
              logInfo("Existing folders fetched", {
                clientId: client._id,
                folderCount: folderSet.size,
              });
            } else {
              console.error(
                "Files data is not in expected format:",
                resultAction.payload
              );
              logError("Unexpected format for files data", {
                clientId: client._id,
                payload: resultAction.payload,
              });
            }
          } else {
            console.error("Failed to fetch uploads:", resultAction.error);
            logError("Failed to fetch uploads", {
              clientId: client._id,
              error: resultAction.error,
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching uploads:", error);
          logError("Error fetching uploads", {
            clientId: client._id,
            error: error.message,
          });
        });
    }
  }, [client, dispatch]);

  // Log or perform actions when `existingFolders` updates
  useEffect(() => {
    if (existingFolders?.length > 0) {
      console.log("Updated folders:", existingFolders);
      logInfo("Existing folders updated", {
        folderCount: existingFolders.length,
        folders: existingFolders,
      });
    }
  }, [existingFolders]);

  const handleCopyEmail = () => {
    if (client?.email) {
      navigator.clipboard.writeText(client.email);
      toast.success("Copied to clipboard!");
      logInfo("Client email copied to clipboard", {
        email: client.email,
      });
    }
  };

  const invoiceColumns = [
    { header: "Invoice ID", accessor: "invoiceId" },
    { header: "Amount", accessor: "amount" },
    { header: "Date", accessor: "date" },
  ];

  const soapNoteColumns = [
    { header: "Date", accessor: "date" },
    { header: "Note", accessor: "note" },
  ];

  if (!client) {
    return <div>Loading client data...</div>;
  }

  return (
    <div className="p-6 bg-white shadow rounded-lg">
      {/* Header */}
      <div className="flex justify-between items-center">
        {/* Left side: Client Name */}
        <div className="flex items-center">
          <div>
            <div className="flex items-center ml-3 text-xl font-bold text-gray-900 sm:truncate sm:leading-9">
              <p>
                {client?.firstName} {client?.lastName} ({clientAge})
              </p>
              <UsersIcon className="h-6 w-6 text-black ml-2" />
            </div>
            <p className="ml-3 sm:truncate">
              {client?.address?.streetNumber} {client?.address?.streetName}
            </p>
            <p className="ml-3 sm:truncate">
              {client?.address?.city} {client?.address?.province}{" "}
              {client?.address?.postalCode}
            </p>
            <p className="ml-3">{client?.phoneNumbers[0]?.number}</p>
            <div>
              <p
                className="ml-3 cursor-pointer text-blue-500 hover:underline"
                onClick={handleCopyEmail}>
                {client?.email}
              </p>
              <ToastContainer autoClose={1000} />
            </div>
          </div>
        </div>

        {/* Appointment and Clinician Details */}
        <div className="flex items-center">
          <div>
            <p>
              Next Appointment Date: {client?.appointments?.[0]?.start || "N/A"}
            </p>
            <p>
              Treating Clinician: {treatingClinician?.firstName}{" "}
              {treatingClinician?.lastName || "N/A"}
            </p>
            <p>
              Supervising Clinician: {supervisingClinician?.firstName}{" "}
              {supervisingClinician?.lastName || "N/A"}
            </p>
          </div>
        </div>

        {/* Right side: Buttons */}
        <div className="flex items-center space-x-4">
          <CircularButton
            onClick={() => {
              logInfo("Client overview closed", {
                clientId: client?._id,
              });
              dispatch(clearSelectedClient());
            }}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            }
            label="Close"
            className="bg-gray-200 text-gray-500 hover:bg-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </CircularButton>
        </div>
      </div>

      {/* Tabs Navigation */}
      <div className="mt-4">
        <nav className="flex space-x-4" aria-label="Tabs">
          <button
            onClick={() => handleTabChange("details")}
            className={classNames(
              selectedTab === "details"
                ? "bg-indigo-600 text-white"
                : "text-gray-500 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}
            aria-current={selectedTab === "details" ? "page" : undefined}>
            Client Details
          </button>
          <button
            onClick={() => handleTabChange("family")}
            className={classNames(
              selectedTab === "family"
                ? "bg-indigo-600 text-white"
                : "text-gray-500 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}
            aria-current={selectedTab === "family" ? "page" : undefined}>
            Family Details
          </button>
          <button
            onClick={() => handleTabChange("invoices")}
            className={classNames(
              selectedTab === "invoices"
                ? "bg-indigo-600 text-white"
                : "text-gray-500 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}>
            Invoices
          </button>
          <button
            onClick={() => handleTabChange("soapNotes")}
            className={classNames(
              selectedTab === "soapNotes"
                ? "bg-indigo-600 text-white"
                : "text-gray-500 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}>
            SOAP Notes
          </button>
          <button
            onClick={() => handleTabChange("upload")}
            className={classNames(
              selectedTab === "upload"
                ? "bg-indigo-600 text-white"
                : "text-gray-500 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}>
            Upload
          </button>
          <button
            onClick={() => handleTabChange("files")}
            className={classNames(
              selectedTab === "files"
                ? "bg-indigo-600 text-white"
                : "text-gray-500 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}>
            Files
          </button>
          <button
            onClick={() => handleTabChange("notes")}
            className={classNames(
              selectedTab === "notes"
                ? "bg-indigo-600 text-white"
                : "text-gray-500 hover:text-gray-700",
              "rounded-md px-3 py-2 text-sm font-medium"
            )}>
            Notes
          </button>
        </nav>
      </div>

      {/* Content for each tab */}
      <div className="mt-6">
        {selectedTab === "details" && <ClientDetails />}
        {selectedTab === "family" && <FamilyDetails />}
        {selectedTab === "invoices" && <ClientInvoices />}
        {selectedTab === "soapNotes" &&
          (client.soapNotes && client.soapNotes.length > 0 ? (
            <Table
              data={client.soapNotes}
              onRowClick={handleSoapNoteClick}
              columns={soapNoteColumns}
            />
          ) : (
            <p>No SOAP notes available. this is for testing</p>
          ))}
        {selectedTab === "upload" && (
          <div>
            {/* Upload Form */}
            <form onSubmit={handleFileUpload}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Folder Name
                </label>
                <div className="flex items-center">
                  <select
                    className="mt-1 block w-1/2 rounded-md border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    value={newFolder ? "" : folderName}
                    onChange={(e) => {
                      const newFolderName = e.target.value.replace(/\s+/g, "_");
                      setFolderName(newFolderName);
                      logInfo("Folder name changed", {
                        newFolderName,
                      });
                    }}
                    disabled={newFolder}
                    required>
                    <option value="" disabled>
                      Select an existing folder
                    </option>
                    {existingFolders.map((folder) => (
                      <option key={folder} value={folder}>
                        {folder}
                      </option>
                    ))}
                  </select>
                  <button
                    type="button"
                    className="ml-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 whitespace-nowrap"
                    onClick={() => {
                      setNewFolder(!newFolder);
                      logInfo("New folder toggle clicked", {
                        newFolder: !newFolder,
                      });
                    }}>
                    {newFolder ? "Select Existing Folder" : "New Folder"}
                  </button>
                </div>
                {newFolder && (
                  <input
                    type="text"
                    className="mt-1 block w-full text-sm text-gray-500"
                    value={folderName}
                    onChange={(e) => {
                      const newFolderName = e.target.value.replace(/\s+/g, "_");
                      setFolderName(newFolderName);
                      logInfo("New folder name entered", {
                        newFolderName,
                      });
                    }}
                    placeholder="Enter new folder name"
                    required
                  />
                )}
                {/* Feedback message */}
                {folderName.includes(" ") && (
                  <span className="text-sm text-yellow-500">
                    Spaces will be replaced with underscores.
                  </span>
                )}
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Upload Document
                  <span className="block text-sm text-red-500">
                    NOTE: Spaces are not permitted in file names.
                  </span>
                </label>

                <input
                  type="file"
                  className="mt-1 block w-full text-sm text-gray-500"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      // Check if file is defined
                      const sanitizedFileName = file.name.replace(/\s+/g, "_"); // Replace spaces with underscores
                      const newFile = new File([file], sanitizedFileName, {
                        type: file.type,
                      });
                      setFile(newFile);
                      logInfo("File selected for upload", {
                        fileName: sanitizedFileName,
                      });
                    } else {
                      console.error("No file selected");
                      logError("No file selected for upload");
                    }
                  }}
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Password (optional)
                </label>
                <div className="flex items-center">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="mt-1 w-40 border rounded-md shadow-sm sm:text-sm focus:w-full transition-all duration-300"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      logInfo("Password field changed", {
                        passwordLength: e.target.value.length,
                      });
                    }}
                    placeholder="Optional password"
                  />
                  <button
                    type="button"
                    className="ml-2"
                    onClick={() => {
                      setShowPassword(!showPassword);
                      logInfo("Password visibility toggled", {
                        showPassword: !showPassword,
                      });
                    }}>
                    {showPassword ? (
                      <EyeIcon className="h-5 w-5 text-gray-700" />
                    ) : (
                      <EyeSlashIcon className="h-5 w-5 text-gray-700" />
                    )}
                  </button>
                </div>
              </div>

              <button
                type="submit"
                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500">
                Upload
              </button>
            </form>

            {uploadResponse && (
              <div
                className={`mt-4 ${
                  uploadResponse.type === "success"
                    ? "text-green-500"
                    : "text-red-500"
                } text-sm font-medium`}>
                {uploadResponse.message}
              </div>
            )}
          </div>
        )}

        {selectedTab === "files" && (
          <ClientUploads client={client} existingFolders={existingFolders} />
        )}

        {selectedTab === "notes" && <ClientNotes client={client} />}
      </div>
    </div>
  );
}

// Helper function for dynamic class names
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
