import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import TwoColumnDescriptionList from "../TwoColumnDescriptionList";
import { useDispatch, useSelector } from "react-redux";
import { fetchAppointmentsByClientId } from "../../../store/thunks/appointmentsThunk";
import FutureAppointments from "../FutureAppointments";
import StepsVerticalListV2 from "../stepsVerticalListV2";
import { AppointmentClientInfo } from "./AppointmentClientInfo";
import { AppointmentInvoices } from "./AppointmentInvoices";
import {
  clearClientAppointments,
  clearSelectedAppointment,
} from "../../../store/slices/appointmentsSlice";
import { AppointmentPayments } from "./AppointmentPayments";
import {
  fetchInvoicePaymentsByAppointmentId,
  fetchInvoicesByAppointmentId,
} from "../../../store/store";
import { clearAppointmentPayments } from "../../../store/slices/paymentsSlice";
import { clearAppointmentInvoices } from "../../../store/slices/invoiceSlice";

export default function AppointmentSummary({
  open,
  onClose,
  onAppointmentClick,
  appointment,
  onReschedule,
}) {
  const dispatch = useDispatch();
  const selectedAppointment = useSelector(
    (state) => state.appointments.selectedAppointment || appointment
  );
  const futureAppointments = useSelector(
    (state) => state.appointments.clientAppointments
  );
  const payments = useSelector((state) => state.payments.appointmentPayments);

  console.log("appt in apptsummary", appointment);
  console.log("selectedappt in apptsummary", selectedAppointment);

  // useEffect to fetch future appointments once when the component mounts
  useEffect(() => {
    if (
      selectedAppointment &&
      selectedAppointment.client &&
      selectedAppointment.client._id
    ) {
      dispatch(fetchAppointmentsByClientId(selectedAppointment.client._id));
      dispatch(fetchInvoicesByAppointmentId(selectedAppointment._id));
      dispatch(fetchInvoicePaymentsByAppointmentId(selectedAppointment._id));
    }

    // return () => {
    //   dispatch(clearClientAppointments());
    //   dispatch(clearSelectedAppointment());
    //   dispatch(clearAppointmentPayments());
    //   dispatch(clearAppointmentInvoices());
    // };
  }, []);

  console.log("selectedappt.client", selectedAppointment.client);
  const handleRescheduleClick = () => {
    onClose();
    onReschedule(selectedAppointment);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50 " onClose={onClose}>
        <div className="fixed inset-0 overflow-hidden ">
          <div className="absolute inset-0 overflow-hidden">
            <div className=" pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel className="pointer-events-auto w-[70vw] h-full flex flex-col bg-white shadow-xl">
                  <div className="flex-1 flex flex-col overflow-y-scroll">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between pt-4">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          Appointment Summary
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={onClose}>
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
                        <div className="lg:col-span-4 bg-white px-4 pb-2">
                          <AppointmentClientInfo />
                        </div>
                        <div className="lg:col-span-4 bg-white px-4 pb-2">
                          {selectedAppointment.history?.some(
                            (entry) => entry.status === "Treated"
                          ) && <AppointmentInvoices />}
                        </div>

                        <div className="lg:col-span-4 bg-white px-4 pb-2">
                          {payments?.length > 0 && <AppointmentPayments />}
                        </div>
                        <div className="lg:col-span-2 bg-white pl-4">
                          <TwoColumnDescriptionList
                            type="appointment"
                            appointment={selectedAppointment}
                            onReschedule={handleRescheduleClick}
                          />
                          <FutureAppointments
                            appointments={futureAppointments}
                            onAppointmentClick={onAppointmentClick}
                          />
                        </div>
                        <div className="lg:col-span-2 bg-white pl-2 pr-4 h-full">
                          <StepsVerticalListV2
                            appointment={selectedAppointment}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
