import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "./auth/authContext";
import App from "./App";
import Login from "./auth/login";
import Register from "./auth/register";
import PasswordReset from "./auth/passwordReset";
import { persistor } from "./store/store";
import { useState, useEffect } from "react";

export default function AppRouter() {
  const { isAuthenticated, user } = useAuth();
  const [isResetting, setIsResetting] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleResetRedux = async () => {
    console.log("Resetting redux");
    setIsResetting(true);
    
    // Animate progress over 3 seconds
    const startTime = Date.now();
    const animate = () => {
      const elapsed = Date.now() - startTime;
      const newProgress = Math.min((elapsed / 3000) * 100, 100);
      setProgress(newProgress);
      
      if (elapsed < 3000) {
        requestAnimationFrame(animate);
      } else {
        // Clear redux and reload after animation
        localStorage.removeItem("persist:ClinicFlow");
        window.location.href = '/login';
      }
    };
    
    requestAnimationFrame(animate);
  };

  return (
    <Routes>
      {/* Protected Routes */}
      <Route
        path="/dashboard"
        element={
          isAuthenticated ? (
            user.passwordResetRequired ? (
              <Navigate to="/password-reset" replace />
            ) : (
              <App />
            )
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      {/* ... other protected routes ... */}

      {/* Password Reset Required Route */}
      <Route
        path="/password-reset"
        element={
          isAuthenticated && user.passwordResetRequired ? (
            <PasswordReset />
          ) : (
            <Navigate to="/dashboard" replace />
          )
        }
      />

      {/* Public Routes */}
      <Route
        path="/login"
        element={
          isAuthenticated ? (
            user.passwordResetRequired ? (
              <Navigate to="/password-reset" replace />
            ) : (
              <Navigate to="/dashboard" replace />
            )
          ) : (
            <Login />
          )
        }
      />
      <Route path="/register" element={<Register />} />

      {/* Redux Reset Route */}
      <Route 
        path="/reset-redux" 
        element={
          <ResetReduxComponent progress={progress} handleResetRedux={handleResetRedux} />
        }
      />

      {/* Redirect to Dashboard if authenticated */}
      <Route
        path="/"
        element={
          isAuthenticated ? (
            user.passwordResetRequired ? (
              <Navigate to="/password-reset" replace />
            ) : (
              <Navigate to="/dashboard" replace />
            )
          ) : (
            <Login />
          )
        }
      />
    </Routes>
  );
}

// Separate component to handle the reset functionality
function ResetReduxComponent({ progress, handleResetRedux }) {
  useEffect(() => {
    // Start reset process immediately when component mounts
    handleResetRedux();
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg shadow-xl text-center">
        <div className="mb-4">
          <div className="w-48 h-2 bg-gray-200 rounded-full overflow-hidden">
            <div 
              className="h-full bg-indigo-600 transition-all duration-300 ease-out"
              style={{width: `${progress}%`}}
            />
          </div>
        </div>
        <p className="text-gray-600">Cleaning up...</p>
      </div>
    </div>
  );
}
