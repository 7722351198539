import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modifyUser } from "../../../store/thunks/userThunk";
import { fetchClinics } from "../../../store/thunks/clinicThunk";

const UserAvailabilityInformation = ({ user = {}, onComplete }) => {
  const dispatch = useDispatch();
  const clinics = useSelector((state) => state.clinics.clinics);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [availability, setAvailability] = useState({});
  const [saveStatus, setSaveStatus] = useState('idle'); // 'idle' | 'saving' | 'saved'

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  // Fetch clinics on component mount
  useEffect(() => {
    dispatch(fetchClinics());
  }, [dispatch]);

  // Load the initial availability when user data is available
  useEffect(() => {
    const initialAvailability = {};
    user.availability?.forEach((entry) => {
      initialAvailability[entry.clinic] = entry.schedule;
    });
    setAvailability(initialAvailability);
  }, [user]);

  const handleClinicSelection = (clinicId) => {
    setSelectedClinic(clinicId);

    // If clinic's schedule is not set, initialize it with empty slots for each day
    if (!availability[clinicId]) {
      const emptySchedule = daysOfWeek.reduce((acc, day) => {
        acc[day] = [{ start: "", end: "" }];
        return acc;
      }, {});
      setAvailability((prev) => ({
        ...prev,
        [clinicId]: emptySchedule,
      }));
    }
  };

  const handleAddTimeSlot = (day) => {
    setAvailability((prev) => ({
      ...prev,
      [selectedClinic]: {
        ...prev[selectedClinic],
        [day]: [...prev[selectedClinic][day], { start: "", end: "" }],
      },
    }));
  };

  const handleRemoveTimeSlot = (day, index) => {
    setAvailability((prev) => {
      const updatedDaySlots = prev[selectedClinic][day].filter(
        (_, i) => i !== index
      );
      return {
        ...prev,
        [selectedClinic]: {
          ...prev[selectedClinic],
          [day]: updatedDaySlots,
        },
      };
    });
  };

  const handleTimeChange = (day, index, field, value) => {
    setAvailability((prev) => {
      const updatedDaySlots = prev[selectedClinic][day].map((slot, i) =>
        i === index ? { ...slot, [field]: value } : slot
      );
      return {
        ...prev,
        [selectedClinic]: {
          ...prev[selectedClinic],
          [day]: updatedDaySlots,
        },
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSaveStatus('saving');
    const availabilityArray = Object.entries(availability).map(
      ([clinicId, schedule]) => ({
        clinic: clinicId,
        schedule,
      })
    );

    dispatch(
      modifyUser({
        userId: user._id || user.userId,
        userData: { availability: availabilityArray },
      })
    )
      .then((response) => {
        console.log("Availability saved successfully:", response);
        setSaveStatus('saved');
        onComplete && onComplete(availabilityArray);
        
        // Reset to idle after 2 seconds
        setTimeout(() => {
          setSaveStatus('idle');
        }, 2000);
      })
      .catch((error) => {
        console.error("Error saving availability:", error);
        setSaveStatus('idle');
      });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Select Clinic
        </label>
        <select
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          onChange={(e) => handleClinicSelection(e.target.value)}
          value={selectedClinic || ""}>
          <option value="">Select a clinic</option>
          {clinics.map((clinic) => (
            <option key={clinic._id} value={clinic._id}>
              {clinic.name}
            </option>
          ))}
        </select>
      </div>

      {selectedClinic && availability[selectedClinic] && (
        <div className="mt-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Set Availability for{" "}
            {clinics.find((c) => c._id === selectedClinic)?.name}
          </h3>
          {daysOfWeek.map((day) => (
            <div key={day} className="mb-4">
              <h4 className="text-md font-medium text-gray-700 mb-2">{day}</h4>
              {availability[selectedClinic][day].map((slot, index) => (
                <div key={index} className="flex items-center space-x-2 mb-2">
                  <input
                    type="time"
                    value={slot.start}
                    onChange={(e) =>
                      handleTimeChange(day, index, "start", e.target.value)
                    }
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <span>to</span>
                  <input
                    type="time"
                    value={slot.end}
                    onChange={(e) =>
                      handleTimeChange(day, index, "end", e.target.value)
                    }
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveTimeSlot(day, index)}
                    className="text-red-600 hover:text-red-800">
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => handleAddTimeSlot(day)}
                className="mt-2 inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Add Time Slot
              </button>
            </div>
          ))}
        </div>
      )}

      <div className="pt-5 flex justify-end">
        <button
          type="submit"
          disabled={saveStatus === 'saving'}
          className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
            saveStatus === 'saving' 
              ? 'bg-indigo-400'
              : saveStatus === 'saved'
                ? 'bg-green-600 hover:bg-green-700'
                : 'bg-indigo-600 hover:bg-indigo-700'
          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}>
          {saveStatus === 'saving' 
            ? 'Saving...' 
            : saveStatus === 'saved'
              ? 'Saved!'
              : 'Save Availability Information'}
        </button>
      </div>
    </form>
  );
};

export default UserAvailabilityInformation;
