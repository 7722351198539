import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAppointmentsByClientId,
  notifyClientByEmail,
  updateAppointmentStatus,
  updateAppointment,
  fetchAppointmentById,
} from "../../store/thunks/appointmentsThunk";
import CancelAppointmentModal from "./CancelAppointmentModal";
import SOAPFormModal from "./SOAPForm";
import AppointmentButtonContainer from "./AppointmentButtonContainer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchDropdown from "./SearchDropdown";
import {
  clearSelectedAppointment,
  clearClientAppointments,
} from "../../store/slices/appointmentsSlice";
import StatusLights from "./StatusLights";
import FamilyDetails from "../../pages/clientsPage/FamilyDetails";

export default function TwoColumnDescriptionList({
  type,
  appointment,
  client,
  refetchAppointments,
  onAppointmentCancel,
  onEmailSent,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [showSOAPForm, setShowSOAPForm] = useState(false);
  const [soapFormAppointment, setSoapFormAppointment] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [showChangeServiceModal, setShowChangeServiceModal] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [showModifyBlockModal, setShowModifyBlockModal] = useState(false);
  const [blockNumber, setBlockNumber] = useState(1); // Default to 1 for starting block number

  // Conditional default values for start and end dates
  const [newStartDate, setNewStartDate] = useState(
    appointment ? new Date(appointment.start) : new Date()
  );
  const [rescheduleStatus, setRescheduleStatus] = useState(null); // For feedback
  const [rescheduleError, setRescheduleError] = useState(""); // For error messages
  const [changeServiceStatus, setChangeServiceStatus] = useState(null); // For feedback
  const [changeServiceError, setChangeServiceError] = useState(""); // For error messages
  const [showParentsComponent, setShowParentsComponent] = useState(false);

  console.log("showParentsComponent", showParentsComponent);

  const selectedAppointment = useSelector(
    (state) => state.appointments.selectedAppointment || appointment
  );

  useEffect(() => {
    if (selectedAppointment && selectedAppointment.client) {
      dispatch(fetchAppointmentsByClientId(selectedAppointment.client._id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (selectedAppointment) {
      dispatch(fetchAppointmentById(selectedAppointment._id));
    }
  }, [dispatch]);

  if (type === "client" && !client) {
    return <p>No client information available.</p>;
  }

  if (type === "appointment" && !appointment) {
    return <p>No appointment information available.</p>;
  }

  const handleButtonClick = (action) => {
    if (action.startsWith("status-")) {
      const nextStatus = action.split("status-")[1];
      handleNextStatus(nextStatus);
    } else {
      switch (action) {
        case "cancel":
          handleCancel();
          break;
        case "reschedule":
          handleReschedule();
          break;
        case "notify":
          handleNotifyClient();
          break;
        case "changeService":
          handleChangeService();
          break;
        case "createSOAPNote":
          handleCreateSOAPNote();
          break;
        case "modifyBlock": // Add this case for block modification
          setShowModifyBlockModal(true);
          break;
        default:
          console.warn(`Unknown button action: ${action}`);
      }
    }
  };

  const handleNextStatus = (nextStatus) => {
    if (nextStatus) {
      dispatch(
        updateAppointmentStatus({
          appointmentId: appointment._id,
          status: nextStatus,
          comments: `Status changed to ${nextStatus}`,
          userId: user._id,
        })
      );
    } else {
      console.log("No further status available");
    }
  };

  const handleCreateSOAPNote = () => {
    console.log("Opening SOAP form");
    setSoapFormAppointment(appointment);
    setShowSOAPForm(true);
  };

  const handleReschedule = () => {
    setShowRescheduleModal(true);
    refetchAppointments(appointment.clinician._id);
  };

  const handleChangeService = () => {
    setShowChangeServiceModal(true);
    // refetchAppointments(appointment.clinician._id);
    // refetchAppointments();
  };

  const handleSaveModifyBlock = async () => {
    if (appointment) {
      const updatedData = {
        isBlock: true,
        blockInfo: {
          totalSessions: 10,
          sessionsCompleted: 0,
          blockNumber: blockNumber,
        },
      };

      try {
        await dispatch(
          updateAppointment({
            id: appointment._id,
            updatedData,
          })
        );
        setShowModifyBlockModal(false); // Close modal
      } catch (error) {
        console.error("Failed to modify block:", error);
      }
    }
  };

  const handleSaveReschedule = async () => {
    if (appointment) {
      const calculatedEndDate = new Date(newStartDate.getTime() + 30 * 60000); // 30 minutes after start date

      const updatedData = {
        start: newStartDate.toISOString(),
        end: calculatedEndDate.toISOString(),
      };

      try {
        await dispatch(
          updateAppointment({
            id: appointment._id,
            updatedData,
          })
        ).unwrap();

        // Update appointment status to "Rescheduled"
        await dispatch(
          updateAppointmentStatus({
            appointmentId: appointment._id,
            status: "Changed",
            comments: "Status changed to Changed",
            userId: user._id,
          })
        ).unwrap();

        setRescheduleStatus("success"); // Set success feedback
        setRescheduleError(""); // Clear any previous errors
        setShowRescheduleModal(false);
      } catch (error) {
        setRescheduleStatus("error"); // Set error feedback
        setRescheduleError(
          "Failed to reschedule the appointment. Please try again."
        ); // Display error message
      }
    }
  };

  const handleSaveChangeService = async () => {
    if (appointment) {
      const updatedData = {
        service: selectedService,
        title: selectedService.description,
      };

      try {
        await dispatch(
          updateAppointment({
            id: appointment._id,
            updatedData,
          })
        ).unwrap();

        await dispatch(
          updateAppointmentStatus({
            appointmentId: appointment._id,
            status: "Changed",
            comments: "Status changed to Changed",
            userId: user._id,
          })
        ).unwrap();

        setChangeServiceStatus("success"); // Set success feedback
        setChangeServiceError(""); // Clear any previous errors
        setShowChangeServiceModal(false);
        refetchAppointments();
      } catch (error) {
        setChangeServiceStatus("error"); // Set error feedback
        setChangeServiceError(
          "Failed to change the service. Please try again."
        ); // Display error message
      }
    }
  };

  const handleSoapClose = () => {
    setShowSOAPForm(false);
    dispatch(fetchAppointmentById(appointment._id));
  };

  const handleCancel = () => {
    console.log("Cancelling appointment", appointment);
    setShowCancelModal(true);
  };

  const handleConfirmCancel = async () => {
    if (!appointment) return;

    const validStatus = "Cancelled";
    const updatedData = {
      status: [validStatus],
      history: [
        ...appointment.history,
        {
          status: validStatus,
          timestamp: new Date(),
          comments: "Appointment cancelled",
          user: user._id,
        },
      ],
    };

    try {
      await dispatch(
        updateAppointment({
          id: appointment._id,
          updatedData,
        })
      ).unwrap();

      onAppointmentCancel();
      setShowCancelModal(false);
    } catch (error) {
      console.error("Failed to cancel appointment:", error);
    }
  };

  const handleModalClose = () => {
    console.log("Handle Modal Close - Two Column");
    dispatch(clearSelectedAppointment());
    dispatch(clearClientAppointments());
    setShowSOAPForm(false);
    refetchAppointments(appointment.clinician._id);
  };

  const handleNotifyClient = () => {
    dispatch(notifyClientByEmail({ appointment, user }));
    refetchAppointments(appointment.clinician._id);
    onEmailSent();
  };

  function calculateAge(dob) {
    if (!dob) return "N/A";
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return isNaN(age) ? "N/A" : age;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      timeZone: "UTC", // Force UTC time zone to avoid local time zone shifts
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const formatPrice = (price) => {
    if (typeof price !== "number" || isNaN(price)) {
      return "$0.00";
    }
    return `$${price.toFixed(2)}`;
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const timeOptions = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
    return `${date.toLocaleDateString(
      "en-US",
      options
    )}, ${date.toLocaleTimeString("en-US", timeOptions)}`;
  };

  return (
    <div className="border border-gray-200 rounded-md px-4 py-4">
      <div>
        <div className="flex items-center justify-between px-2s">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            {type === "client"
              ? "Client Info"
              : type === "appointment"
              ? "Appointment Info"
              : "Future Appointment Info"}
          </h3>
          {type === "client" && client.parents.length > 0 && (
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => setShowParentsComponent(!showParentsComponent)}
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Parents
              </button>
            </div>
          )}
          {type === "appointment" && (
            <div className="px-2 py-4 text-sm text-gray-500">
              <span>
                <StatusLights appointment={selectedAppointment} />
              </span>
            </div>
          )}
        </div>

        <div>
          <dl
            className={`grid gap-x-2 ${
              type === "client"
                ? "grid-cols-1 sm:grid-cols-3"
                : "grid-cols-1 sm:grid-cols-2"
            }`}></dl>
        </div>

        <div className="overflow-x-auto mt-2">
          <div className="flex flex-nowrap px-2 sm:px-0 space-x-2">
            {/* Button container */}
            {(type === "appointment" || type === "futureappointment") && (
              <AppointmentButtonContainer
                appointment={appointment}
                onButtonClick={handleButtonClick}
              />
            )}
          </div>
        </div>
      </div>
      <div className="mt-2">
        <dl
          className={`grid gap-x-2 ${
            type === "client"
              ? "grid-cols-1 sm:grid-cols-3"
              : "grid-cols-1 sm:grid-cols-2"
          }`}>
          {type === "client" && client && (
            <>
              <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Client Name
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">{`${selectedAppointment.client.firstName} ${selectedAppointment.client.lastName}`}</dd>
              </div>
              <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Email
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                  {selectedAppointment.client.email}
                </dd>
              </div>
              <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Phone Numbers
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                  {selectedAppointment.client.phoneNumbers &&
                  selectedAppointment.client.phoneNumbers.length > 0
                    ? selectedAppointment.client.phoneNumbers.map((phone) => (
                        <div key={phone._id}>
                          {phone.number} ({phone.type})
                        </div>
                      ))
                    : "N/A"}
                </dd>
              </div>
              <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Date of Birth
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                  {formatDate(selectedAppointment.client.dateOfBirth)}
                </dd>
              </div>
              <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Age
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                  {calculateAge(selectedAppointment.client.dateOfBirth)}
                </dd>
              </div>
            </>
          )}
          {(type === "appointment" || type === "futureappointment") &&
            appointment && (
              <>
                <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Service
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                    {appointment.title}
                  </dd>
                </div>
                <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Cost
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                    {formatPrice(appointment.service.price)}
                  </dd>
                </div>
                <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Date & Time
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                    {formatDateTime(appointment.start)}
                  </dd>
                </div>
                <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Notes/Remarks
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1 overflow-x-auto">
                    {appointment.notes && appointment.notes.trim()
                      ? appointment.notes
                      : "N/A"}
                  </dd>
                </div>
                <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Status
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                    {appointment.status}
                  </dd>
                </div>
                <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Block Information
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                    {appointment.blockInfo ? (
                      <div>
                        Block appointment #{appointment.blockInfo.blockNumber} /{" "}
                        {appointment.blockInfo.totalSessions}
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </dd>
                </div>

                <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Treating Clinician
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                    {`${appointment.clinician.firstName} ${appointment.clinician.lastName}`}
                  </dd>
                </div>
                <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Supervising Clinician
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-1">
                    {selectedAppointment?.client?.supervisingClinician?.current
                      ?.firstName &&
                    selectedAppointment?.client?.supervisingClinician?.current
                      ?.lastName
                      ? `${selectedAppointment.client.supervisingClinician.current.firstName} ${selectedAppointment.client.supervisingClinician.current.lastName}`
                      : "N/A"}
                  </dd>
                </div>
              </>
            )}
        </dl>
      </div>
      {showParentsComponent && <FamilyDetails display client={client} />}
      {showCancelModal && (
        <CancelAppointmentModal
          isOpen={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          onConfirm={handleConfirmCancel}
        />
      )}
      {/* Reschedule Modal */}
      {showRescheduleModal && (
        <div className="modal fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex">
          <div className="bg-white rounded-lg p-6 mx-auto my-auto">
            <h3 className="text-lg font-semibold mb-4">
              Reschedule Appointment
            </h3>

            <div className="mb-4">
              <label className="block text-gray-700">Start Date & Time</label>
              <DatePicker
                selected={newStartDate}
                onChange={(date) => setNewStartDate(date)}
                showTimeSelect
                timeIntervals={5}
                dateFormat="Pp"
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            {/* Feedback Message */}
            {rescheduleStatus === "success" && (
              <p className="text-green-500">
                Appointment successfully rescheduled!
              </p>
            )}
            {rescheduleStatus === "error" && (
              <p className="text-red-500">{rescheduleError}</p>
            )}

            <div className="flex justify-end mt-4">
              <button
                className="bg-gray-500 text-white rounded px-4 py-2 mr-2"
                onClick={() => setShowRescheduleModal(false)}>
                Close
              </button>
              <button
                className="bg-blue-500 text-white rounded px-4 py-2"
                onClick={handleSaveReschedule}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Change Service Modal */}
      {showChangeServiceModal && (
        <div className="modal fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex">
          <div className="bg-white rounded-lg p-6 mx-auto my-auto w-3/4">
            {" "}
            {/* Increased width to 3/4 */}
            <h3 className="text-lg font-semibold mb-4">Change Service</h3>
            <div className="mb-4">
              {/* <label className="block text-gray-700">Change Service</label> */}
              <SearchDropdown
                prefix=":service:"
                displayFields={["serviceCode", "description", "price"]}
                onSelection={(service) => setSelectedService(service)}
                placeholder={"Search for a service by name or service code"}
              />
            </div>
            {/* Feedback Message */}
            {changeServiceStatus === "success" && (
              <p className="text-green-500">Service successfully changed!</p>
            )}
            {changeServiceStatus === "error" && (
              <p className="text-red-500">{changeServiceError}</p>
            )}
            <div className="flex justify-end mt-4">
              <button
                className="bg-gray-500 text-white rounded px-4 py-2 mr-2"
                onClick={() => setShowChangeServiceModal(false)}>
                Close
              </button>
              <button
                className="bg-blue-500 text-white rounded px-4 py-2"
                onClick={handleSaveChangeService}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Modify Block Modal */}
      {showModifyBlockModal && (
        <div className="modal fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex">
          <div className="bg-white rounded-lg p-6 mx-auto my-auto">
            <h3 className="text-lg font-semibold mb-4">Modify Block</h3>

            <div className="mb-4">
              <label className="block text-gray-700">
                Start from block Number
              </label>
              <input
                type="number"
                value={blockNumber}
                onChange={(e) => setBlockNumber(e.target.value)}
                min="1"
                max="10"
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div className="flex justify-end mt-4 gap-2">
              <button
                className="rounded-md bg-gray-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                onClick={() => setShowModifyBlockModal(false)}>
                Cancel
              </button>
              <button
                className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleSaveModifyBlock}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      {showSOAPForm && soapFormAppointment && (
        <SOAPFormModal
          onClose={handleSoapClose}
          appointment={soapFormAppointment}
        />
      )}
    </div>
  );
}
