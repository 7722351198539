import InputField from "../../applicationUi/components/intake/InputField";
import SelectField from "../../applicationUi/components/intake/SelectField";
const ClientInformationSection = ({ formData, handleChange }) => (
  <section>
    <h2 className="text-lg font-bold">Client Information</h2>
    <div className="grid grid-cols-3 gap-4">
      <InputField
        label="First Name"
        name="client.firstName"
        value={formData.client.firstName}
        onChange={handleChange}
      />
      <InputField
        label="Last Name"
        name="client.lastName"
        value={formData.client.lastName}
        onChange={handleChange}
      />
      <SelectField
        label="Gender"
        name="client.gender"
        value={formData.client.gender}
        onChange={handleChange}
        options={[
          { value: "", label: "Select Gender" },
          { value: "male", label: "Male" },
          { value: "female", label: "Female" },
          { value: "nonbinary", label: "Non-Binary" },
          { value: "other", label: "Other" },
        ]}
      />
      <InputField
        label="Date of Birth"
        name="client.dob"
        value={formData.client.dob}
        type="date"
        onChange={handleChange}
      />
      <InputField
        label="Doctor's Name"
        name="client.doctorName"
        value={formData.client.doctorName}
        onChange={handleChange}
      />
      <InputField
        label="Doctor's Phone"
        name="client.doctorPhone"
        value={formData.client.doctorPhone}
        onChange={handleChange}
      />
      <InputField
        label="Address"
        name="client.address"
        value={formData.client.address}
        onChange={handleChange}
      />
      <InputField
        label="Referral Source"
        name="client.referralSource"
        value={formData.client.referralSource}
        onChange={handleChange}
      />
      <InputField
        label="Referral Reason"
        name="client.referralReason"
        value={formData.client.referralReason}
        onChange={handleChange}
      />
    </div>
  </section>
);

export default ClientInformationSection;
