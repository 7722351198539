import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClients,
  deleteClient,
  fetchClientById,
} from "../../store/thunks/clientsThunk";
import ConfirmDeleteDialog from "../../dialogs/ConfirmDeleteDialog";
import ClientOverview from "./ClientOverview";
import Table from "../../applicationUi/components/Table";
import SearchDropdown from "../../applicationUi/components/SearchDropdown";
import { format, differenceInYears } from "date-fns";
import { logInfo, logError } from "../../utils/logger";
import { clearSelectedClinicians } from "../../store/slices/cliniciansSlice";
import {
  clearSelectedClient,
  setClient,
} from "../../store/slices/clientsSlice";
import { setClickedItem } from "../../store/slices/appStateSlice";

export default function ClientsPage({ clickedItem }) {
  const dispatch = useDispatch();
  const {
    clients = [],
    pagination = { currentPage: 1, pageSize: 10 },
    status: clientStatus,
    error: clientError,
    selectedClient,
  } = useSelector((state) => state.clients);

  const [isDeleteClientModalOpen, setIsDeleteClientModalOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: null,
  });

  console.log("selectedClient", selectedClient);

  // useEffect(() => {
  //   dispatch(clearSelectedClient());
  // }, []);

  useEffect(() => {
    if (clickedItem && clickedItem._id) {
      logInfo("Fetching specific client", { clientId: clickedItem._id });
      dispatch(fetchClientById(clickedItem._id));
    } else {
      logInfo("Fetching clients", {
        page: pagination.currentPage,
        limit: pagination.pageSize,
        sortKey: sortConfig.key,
        sortDirection: sortConfig.direction,
      });
      dispatch(
        fetchClients({
          page: pagination.currentPage,
          limit: pagination.pageSize,
          sort: sortConfig.key
            ? {
                [sortConfig.key]: sortConfig.direction === "ascending" ? 1 : -1,
              }
            : undefined,
        })
      );
    }
  }, [
    dispatch,
    pagination.currentPage,
    pagination.pageSize,
    clickedItem,
    clients.length,
    sortConfig,
  ]);

  const handleRowClick = (clientId) => {
    console.log("clientId", clientId);
    logInfo("Client row clicked", { clientId: clientId });
    dispatch(fetchClientById(clientId));
  };

  const handleSearchClick = (client) => {
    console.log("Search Clicked client", client);
    logInfo("Client selected from search", { clientId: client._id });
    dispatch(setClient(client));
    dispatch(setClickedItem(client));
  };

  const handleBackToClients = () => {
    logInfo("Navigating back to client list");
    dispatch(clearSelectedClient());
    dispatch(clearSelectedClinicians());
  };

  const handleSortChange = (key, direction) => {
    console.log("handleSortChange", { key, direction });
    logInfo("Sort changed", { key, direction });
    setSortConfig({ key, direction });
  };

  const formattedClients =
    clients?.map((client) => {
      const dateOfBirth = client.dateOfBirth
        ? new Date(client.dateOfBirth)
        : null;
      const formattedDateOfBirth = dateOfBirth
        ? format(dateOfBirth, "MMMM d, yyyy")
        : "N/A";
      const age = dateOfBirth
        ? differenceInYears(new Date(), dateOfBirth)
        : "N/A";

      const formattedPhoneNumbers = client.phoneNumbers
        ? client.phoneNumbers.map((phone) => `${phone.number}`).join(", ")
        : "N/A";

      return {
        ...client,
        dateOfBirth: formattedDateOfBirth,
        age,
        formattedPhoneNumbers,
      };
    }) || [];

  logInfo("Rendered ClientsPage", {
    clientCount: clients.length,
    selectedClient: selectedClient ? selectedClient._id : null,
    clientStatus,
    paginationInfo: pagination,
    sortConfig,
  });

  return (
    <div>
      {selectedClient ? (
        <div className="p-6">
          <ClientOverview />
        </div>
      ) : (
        <>
          <div className="m-8">
            <div className="text-base font-semibold leading-6 text-gray-900">
              Search for clients by first name, last name, email or phone
              number:
            </div>
            <SearchDropdown
              prefix=":client:"
              displayFields={["firstName", "lastName", "email", "phoneNumbers"]}
              onSelection={(client) => handleSearchClick(client)}
              placeholder={"Type to start searching..."}
            />
          </div>

          {clientStatus === "loading" && <p>Loading clients...</p>}
          {clientStatus === "failed" && (
            <p>
              Error loading clients:{" "}
              {typeof clientError === "object"
                ? JSON.stringify(clientError)
                : clientError}
            </p>
          )}
          {clientStatus === "succeeded" && clients.length === 0 && (
            <p>No clients available</p>
          )}
          {clientStatus === "succeeded" && clients.length > 0 && (
            <Table
              columns={[
                { header: "First Name", accessor: "firstName" },
                { header: "Last Name", accessor: "lastName" },
                {
                  header: "Date of Birth",
                  accessor: "dateOfBirth",
                },
                { header: "Age", accessor: "age" },
                { header: "Email", accessor: "email" },
                {
                  header: "Phone Numbers",
                  accessor: "formattedPhoneNumbers",
                },
              ]}
              data={formattedClients}
              onRowClick={(row) => handleRowClick(row)}
              onSortChange={handleSortChange}
              actions={[
                {
                  label: "Add Client",
                  onClick: () => {
                    logInfo("Add Client button clicked");
                  },
                },
              ]}
              title="Clients"
              description="A list of all clients. Use pagination to view more."
              showPagination={pagination.totalPages > 1}
              currentPage={pagination.currentPage}
              totalPages={pagination.totalPages}
              onPageChange={(page) => {
                logInfo("Pagination changed", {
                  newPage: page,
                });
                dispatch(
                  fetchClients({
                    page,
                    limit: pagination.pageSize,
                    sort: sortConfig.key
                      ? {
                          [sortConfig.key]:
                            sortConfig.direction === "ascending" ? 1 : -1,
                        }
                      : undefined,
                  })
                );
              }}
            />
          )}
        </>
      )}

      {isDeleteClientModalOpen && (
        <ConfirmDeleteDialog
          open={isDeleteClientModalOpen}
          setOpen={setIsDeleteClientModalOpen}
          onDelete={() => {
            logInfo("Deleting client", {
              clientId: clientToDelete,
            });
            dispatch(deleteClient(clientToDelete));
          }}
        />
      )}
    </div>
  );
}
