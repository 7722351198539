// src/store/thunks/userThunks.js

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { generateConfig, generateUploadConfig } from "../../utils/api";
import { handleApiError } from "../../utils/apiErrorHandler";

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(`${baseURL}/api/users`, config);
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Async Thunk for creating a user
export const createUser = createAsyncThunk(
  "users/createUser",
  async (userData, { dispatch, rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.post(
        `${baseURL}/api/users`,
        userData,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Async Thunk for modifying a user using PATCH
export const modifyUser = createAsyncThunk(
  "users/editUser",
  async ({ userId, userData }, { dispatch, rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.patch(
        `${baseURL}/api/users/${userId}`,
        userData,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Thunk for updating clinician information
export const updateUserClinicianInfo = createAsyncThunk(
  "users/updateClinicianInfo",
  async (
    { userId, clinicianData },
    { dispatch, rejectWithValue, getState }
  ) => {
    const state = getState();
    const token = state.auth.token;
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.patch(
        `${baseURL}/api/users/clinicians/${userId}`,
        clinicianData,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// export const modifyUser = createAsyncThunk(
//   "users/editUser",
//   async ({ userId, userData }, { dispatch, rejectWithValue, getState }) => {
//     const state = getState();
//     const token = state.auth.token; // Assuming token is stored in auth state
//     const { baseURL, config } = generateConfig({ token });

//     console.log("User data:", userData);
//     console.log("User ID:", userId);

//     try {
//       const response = await axios.put(
//         `${baseURL}/api/users/${userId}`,
//         userData,
//         config
//       );
//       return response.data; // Return the updated user data
//     } catch (error) {
//       return handleApiError(error, rejectWithValue, dispatch);
//     }
//   }
// );

export const fetchUserById = createAsyncThunk(
  "users/fetchUser",
  async (userId, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/users/${userId}`,
        config
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const resetUserPassword = createAsyncThunk(
  "users/resetUserPassword",
  async (userData, { dispatch, rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token; // Assuming token is stored in auth state
    const { baseURL, config } = generateConfig({ token });

    try {
      const response = await axios.put(
        `${baseURL}/api/users`,
        userData,
        config
      );
      return response.data; // Return the created user data
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Upload profile picture thunk
export const uploadUserPicture = createAsyncThunk(
  "userPicture/upload",
  async ({ userId, formData }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateUploadConfig({ token });

      // Append the userId to the formData
      formData.append("userId", userId);

      const response = await axios.post(
        `${baseURL}/api/users/${userId}/profile-picture`,
        formData,
        config
      );

      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Delete profile picture thunk
export const deleteUserPicture = createAsyncThunk(
  "userPicture/delete",
  async ({ userId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      await axios.delete(
        `${baseURL}/api/users/${userId}/profile-picture`,
        config
      );

      return userId;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchUserPicture = createAsyncThunk(
  "userPicture/fetch",
  async ({ userId }, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.get(
        `${baseURL}/api/users/${userId}/profile-picture`,
        { ...config, responseType: "blob" }
      );

      const imageUrl = URL.createObjectURL(response.data);
      return imageUrl;
    } catch (error) {
      if (error.response?.status === 404) {
        return null; // Return null if the profile picture is not found
      }
      return rejectWithValue(
        error.response?.data || "Failed to fetch picture."
      );
    }
  }
);
