import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createClient, createParent } from "../store/thunks/clientsThunk";
import GoogleMapsAutocomplete from "../applicationUi/components/GoogleAutoComplete";
import ProvinceSelector from "../applicationUi/components/ProvinceSelector";
import GenderSelector from "../applicationUi/components/GenderSelector";
import { Switch } from "@headlessui/react";
import SearchDropdown from "../applicationUi/components/SearchDropdown";
import { XMarkIcon } from "@heroicons/react/24/outline";

function formatPhoneNumber(phoneNumber) {
  // Remove all non-digit characters
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  // Check if the input is of correct length
  if (cleaned.length !== 10) {
    return phoneNumber; // Return the original if it's not 10 digits
  }

  // Format the cleaned number
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? `${match[1]}-${match[2]}-${match[3]}` : phoneNumber;
}

export default function QuickAddClientModal({ onClose, addParent = false }) {
  const dispatch = useDispatch();
  const [parentAddress, setParentAddress] = useState({
    aptUnit: "",
    streetNumber: "",
    streetName: "",
    city: "",
    province: "",
    postalCode: "",
  });
  const [clientAddress, setClientAddress] = useState({
    aptUnit: "",
    streetNumber: "",
    streetName: "",
    city: "",
    province: "",
    postalCode: "",
  });

  const [parentPhoneNumbers, setParentPhoneNumbers] = useState([
    { type: "", number: "" },
  ]);
  const [clientPhoneNumbers, setClientPhoneNumbers] = useState([
    { type: "", number: "" },
  ]);
  const [clientEmails, setClientEmails] = useState([]);
  const [parentEmails, setParentEmails] = useState([]);
  const [secondParentInfo, setSecondParentInfo] = useState({
    email: "",
    phone: "",
  });
  const [showParentInfo, setShowParentInfo] = useState(
    addParent ? true : false
  );
  const [treatingClinician, setTreatingClinician] = useState(false);
  const [supervisingClinician, setSupervisingClinician] = useState(false);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const handleSave = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const parentData = {
      type: "parent",
      firstName: formData.get("parentFirstName"),
      lastName: formData.get("parentLastName"),
      dateOfBirth: formData.get("parentDateOfBirth"),
      email: formData.get("parentEmail"),
      additionalEmails: parentEmails, // Updated to include parent emails (ADDITIONAL EMAILS)
      phoneNumbers: parentPhoneNumbers.map((phone) => ({
        ...phone,
        number: formatPhoneNumber(phone.number),
      })),
      address: parentAddress,
      relationship: formData.get("parentRelationship"),
      gender: formData.get("parentGender"),
      secondParentEmail: formData.get("secondParentEmail"),
      secondParentPhone: formData.get("secondParentPhone"),
    };

    const clientData = {
      type: "client",
      firstName: formData.get("clientFirstName"),
      lastName: formData.get("clientLastName"),
      dateOfBirth: formData.get("clientDateOfBirth"),
      email: formData.get("clientEmail"),
      additionalEmails: clientEmails, // Updated to include client emails (ADDITIONAL EMAILS)
      phoneNumbers: clientPhoneNumbers.map((phone) => ({
        ...phone,
        number: formatPhoneNumber(phone.number),
      })),
      parents: [parentData].filter(
        (parent) => parent.firstName && parent.lastName
      ),
      address: clientAddress,
      healthCardNumber: formData.get("clientHealthCardNumber"),
      gender: formData.get("gender"),
      treatingClinician: {
        current: treatingClinician._id,
        history: [{ clinician: treatingClinician._id, date: new Date() }],
      },
      supervisingClinician: {
        current: supervisingClinician._id,
        history: [{ clinician: supervisingClinician._id, date: new Date() }],
      },
    };

    try {
      if (addParent) {
        await dispatch(createParent(parentData));
        console.log("Parent created successfully");
      } else {
        await dispatch(createClient(clientData));
        console.log("Client created successfully");
      }
      onClose();
    } catch (error) {
      console.error("Error saving client:", error);
      alert("An unexpected error occurred. Please try again.");
    }
  };

  const handlePlaceSelected = (place, setAddress) => {
    if (place && place.address_components) {
      console.log("Google Maps Place Object:", place);

      const newAddress = {};
      place.address_components.forEach((component) => {
        if (component.types.includes("street_number")) {
          newAddress.streetNumber = component.long_name;
        }
        if (component.types.includes("route")) {
          newAddress.streetName = component.long_name;
        }
        if (component.types.includes("locality")) {
          newAddress.city = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          newAddress.province = component.short_name;
        }
        if (component.types.includes("postal_code")) {
          newAddress.postalCode = component.long_name;
        }
        if (component.types.includes("subpremise")) {
          newAddress.aptUnit = component.long_name;
        }
      });
      console.log("Selected Address:", newAddress);
      setAddress((prev) => ({
        ...prev,
        ...newAddress,
      }));
    }
  };

  const inputFields = [
    "firstName",
    "lastName",
    "dateOfBirth",
    "email",
    "relationship",
    "gender",
    "aptUnit",
    "streetNumber",
    "streetName",
    "city",
    "province",
    "postalCode",
  ];

  const clientInputFields = [
    "firstName",
    "lastName",
    "dateOfBirth",
    "email",
    "healthCardNumber",
    "gender",
    "aptUnit",
    "streetNumber",
    "streetName",
    "city",
    "province",
    "postalCode",
  ];

  const handlePhoneNumberChange = (index, type, value, setPhoneNumbers) => {
    const formattedValue = type === "number" ? formatPhoneNumber(value) : value;
    setPhoneNumbers((prev) =>
      prev.map((phoneNumber, i) =>
        i === index ? { ...phoneNumber, [type]: formattedValue } : phoneNumber
      )
    );
  };

  const addPhoneNumberField = (setPhoneNumbers) => {
    setPhoneNumbers((prev) => [...prev, { type: "", number: "" }]);
  };

  const removePhoneNumberField = (index, setPhoneNumbers) => {
    setPhoneNumbers((prev) => prev.filter((_, i) => i !== index));
  };

  const handleEmailChange = (index, field, value, setEmailList) => {
    setEmailList((prev) =>
      prev.map((emailObj, i) =>
        i === index ? { ...emailObj, [field]: value } : emailObj
      )
    );
  };

  const addEmailField = (setEmailList) => {
    setEmailList((prev) => [...prev, { label: "", email: "" }]);
  };

  const removeEmailField = (index, setEmailList) => {
    setEmailList((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
      <div className="fixed inset-0 bg-gray-900 opacity-50" onClick={onClose} />
      <div className="bg-white rounded-lg p-8 max-w-4xl w-full mx-4 relative z-10 overflow-y-auto max-h-[90vh]">
        <form onSubmit={handleSave}>
          {/* Header */}
          <div className="border-b border-gray-900/10 pb-6 mb-6 relative">
            <button onClick={onClose} className="absolute top-0 right-0">
              <XMarkIcon className="h-5 w-5 text-gray-900" />
            </button>
            <h2 className="text-lg font-semibold leading-7 text-gray-900">
              {addParent ? "Quick Add Parent" : "Quick Add Client"}
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {addParent
                ? "Use this form to quickly add a parent with basic information."
                : "Use this form to quickly add a client-parent pair with basic information."}
            </p>
          </div>

          {/* Parent/Guardian Information */}
          <div className="border-b border-gray-900/10 pb-12">
            <div className="flex justify-between">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Parent/Guardian Information
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Input the parent/guardian information of the client.
                </p>
              </div>
              <Switch
                checked={showParentInfo}
                onChange={setShowParentInfo}
                className={classNames(
                  showParentInfo ? "bg-indigo-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                )}>
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    showParentInfo ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>

              {/* <Label as="span" className="ml-3 text-sm">
                <span className="font-medium text-gray-900">
                  Annual billing
                </span>{" "}
                <span className="text-gray-500">(Save 10%)</span>
              </Label> */}
            </div>

            {showParentInfo && (
              <div>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
                  <div className="sm:col-span-4">
                    <GoogleMapsAutocomplete
                      onPlaceSelected={(place) =>
                        handlePlaceSelected(place, setParentAddress)
                      }
                      inputId="parent-autocomplete"
                    />
                  </div>
                  {inputFields.map((field) => (
                    <div key={`parent-${field}`} className="sm:col-span-1">
                      <label
                        htmlFor={`parent${
                          field.charAt(0).toUpperCase() + field.slice(1)
                        }`}
                        className="block text-sm font-medium leading-6 text-gray-900">
                        {field
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, (str) => str.toUpperCase())}
                      </label>
                      <div className="mt-2">
                        {field === "province" ? (
                          <ProvinceSelector
                            value={parentAddress.province}
                            onChange={(e) =>
                              setParentAddress((prev) => ({
                                ...prev,
                                province: e.target.value,
                              }))
                            }
                          />
                        ) : field === "gender" ? (
                          <GenderSelector
                            value={parentAddress[field]}
                            onChange={(e) =>
                              setParentAddress((prev) => ({
                                ...prev,
                                [field]: e.target.value,
                              }))
                            }
                          />
                        ) : (
                          <input
                            type={field === "dateOfBirth" ? "date" : "text"}
                            name={`parent${
                              field.charAt(0).toUpperCase() + field.slice(1)
                            }`}
                            id={`parent${
                              field.charAt(0).toUpperCase() + field.slice(1)
                            }`}
                            value={parentAddress[field]}
                            onChange={(e) =>
                              setParentAddress((prev) => ({
                                ...prev,
                                [field]: e.target.value,
                              }))
                            }
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="sm:col-span-4">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                      Additional Emails
                    </h3>
                    {parentEmails.map((emailField, index) => (
                      <div key={index} className="flex space-x-2 mb-2">
                        <input
                          type="text"
                          placeholder="Label (e.g., Work)"
                          value={emailField.label}
                          onChange={(e) =>
                            handleEmailChange(
                              index,
                              "label",
                              e.target.value,
                              setParentEmails
                            )
                          }
                          className="block w-1/3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <input
                          type="email"
                          placeholder="Email Address"
                          value={emailField.email}
                          onChange={(e) =>
                            handleEmailChange(
                              index,
                              "email",
                              e.target.value,
                              setParentEmails
                            )
                          }
                          className="block w-2/3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <button
                          type="button"
                          onClick={() =>
                            removeEmailField(index, setParentEmails)
                          }
                          className="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500">
                          Remove
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => addEmailField(setParentEmails)}
                      className="text-sm font-semibold leading-6 text-gray-900">
                      Add Additional Email
                    </button>
                  </div>

                  <div className="sm:col-span-4">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                      Phone Numbers
                    </h3>
                    {parentPhoneNumbers.map((phoneNumber, index) => (
                      <div key={index} className="flex space-x-2 mb-2">
                        <select
                          value={phoneNumber.type}
                          onChange={(e) =>
                            handlePhoneNumberChange(
                              index,
                              "type",
                              e.target.value,
                              setParentPhoneNumbers
                            )
                          }
                          className="block w-1/3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <option value="">Select Type</option>
                          <option value="Home">Home</option>
                          <option value="Work">Work</option>
                          <option value="Mobile">Mobile</option>
                          <option value="Fax">Fax</option>
                          <option value="Other">Other</option>
                        </select>
                        <input
                          type="text"
                          value={phoneNumber.number}
                          onChange={(e) =>
                            handlePhoneNumberChange(
                              index,
                              "number",
                              e.target.value,
                              setParentPhoneNumbers
                            )
                          }
                          className="block w-2/3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <button
                          type="button"
                          onClick={() =>
                            removePhoneNumberField(index, setParentPhoneNumbers)
                          }
                          className="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500">
                          Remove
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => addPhoneNumberField(setParentPhoneNumbers)}
                      className="text-sm font-semibold leading-6 text-gray-900">
                      Add Phone Number
                    </button>
                  </div>
                </div>

                <div className="mt-8">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Second Parent Information
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    Input the second parent's information if available.
                  </p>

                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="secondParentEmail"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Second Parent Email
                      </label>
                      <div className="mt-2">
                        <input
                          type="email"
                          name="secondParentEmail"
                          id="secondParentEmail"
                          value={secondParentInfo.email}
                          onChange={(e) =>
                            setSecondParentInfo((prev) => ({
                              ...prev,
                              email: e.target.value,
                            }))
                          }
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="secondParentPhone"
                        className="block text-sm font-medium leading-6 text-gray-900">
                        Second Parent Phone
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="secondParentPhone"
                          id="secondParentPhone"
                          value={secondParentInfo.phone}
                          onChange={(e) =>
                            setSecondParentInfo((prev) => ({
                              ...prev,
                              phone: e.target.value,
                            }))
                          }
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {!addParent && (
            <>
              {/* Client Information */}
              <div className="mt-8 border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Client Information
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Input the information of the client.
                </p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
                  <div className="sm:col-span-4">
                    <GoogleMapsAutocomplete
                      onPlaceSelected={(place) =>
                        handlePlaceSelected(place, setClientAddress)
                      }
                      inputId="client-autocomplete"
                    />
                  </div>
                  {clientInputFields.map((field) => (
                    <div key={`client-${field}`} className="sm:col-span-1">
                      <label
                        htmlFor={`client${
                          field.charAt(0).toUpperCase() + field.slice(1)
                        }`}
                        className="block text-sm font-medium leading-6 text-gray-900">
                        {field === "email"
                          ? "Primary Email"
                          : field
                              .replace(/([A-Z])/g, " $1")
                              .replace(/^./, (str) => str.toUpperCase())}
                      </label>
                      <div className="mt-2">
                        {field === "province" ? (
                          <ProvinceSelector
                            value={clientAddress.province}
                            onChange={(e) =>
                              setClientAddress((prev) => ({
                                ...prev,
                                province: e.target.value,
                              }))
                            }
                          />
                        ) : field === "gender" ? (
                          <GenderSelector
                            value={clientAddress[field]}
                            onChange={(e) =>
                              setClientAddress((prev) => ({
                                ...prev,
                                [field]: e.target.value,
                              }))
                            }
                          />
                        ) : (
                          <input
                            type={field === "dateOfBirth" ? "date" : "text"}
                            name={`client${
                              field.charAt(0).toUpperCase() + field.slice(1)
                            }`}
                            id={`client${
                              field.charAt(0).toUpperCase() + field.slice(1)
                            }`}
                            value={clientAddress[field]}
                            onChange={(e) =>
                              setClientAddress((prev) => ({
                                ...prev,
                                [field]: e.target.value,
                              }))
                            }
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="sm:col-span-4">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                      Additional Emails
                    </h3>
                    {clientEmails.map((emailField, index) => (
                      <div key={index} className="flex space-x-2 mb-2">
                        <input
                          type="text"
                          placeholder="Label (e.g., Work)"
                          value={emailField.label}
                          onChange={(e) =>
                            handleEmailChange(
                              index,
                              "label",
                              e.target.value,
                              setClientEmails
                            )
                          }
                          className="block w-1/3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <input
                          type="email"
                          placeholder="Email Address"
                          value={emailField.email}
                          onChange={(e) =>
                            handleEmailChange(
                              index,
                              "email",
                              e.target.value,
                              setClientEmails
                            )
                          }
                          className="block w-2/3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <button
                          type="button"
                          onClick={() =>
                            removeEmailField(index, setClientEmails)
                          }
                          className="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500">
                          Remove
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => addEmailField(setClientEmails)}
                      className="text-sm font-semibold leading-6 text-gray-900">
                      Add Additional Email
                    </button>
                  </div>

                  <div className="sm:col-span-4">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">
                      Phone Numbers
                    </h3>
                    {clientPhoneNumbers.map((phoneNumber, index) => (
                      <div key={index} className="flex space-x-2 mb-2">
                        <select
                          value={phoneNumber.type}
                          onChange={(e) =>
                            handlePhoneNumberChange(
                              index,
                              "type",
                              e.target.value,
                              setClientPhoneNumbers
                            )
                          }
                          className="block w-1/3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                          <option value="">Select Type</option>
                          <option value="Home">Home</option>
                          <option value="Work">Work</option>
                          <option value="Mobile">Mobile</option>
                          <option value="Fax">Fax</option>
                          <option value="Other">Other</option>
                        </select>
                        <input
                          type="text"
                          value={phoneNumber.number}
                          onChange={(e) =>
                            handlePhoneNumberChange(
                              index,
                              "number",
                              e.target.value,
                              setClientPhoneNumbers
                            )
                          }
                          className="block w-2/3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                        <button
                          type="button"
                          onClick={() =>
                            removePhoneNumberField(index, setClientPhoneNumbers)
                          }
                          className="rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500">
                          Remove
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() => addPhoneNumberField(setClientPhoneNumbers)}
                      className="text-sm font-semibold leading-6 text-gray-900">
                      Add Phone Number
                    </button>
                  </div>
                </div>
              </div>

              {/* Treating and Supervising Clinician */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8 border-b border-gray-900/10 pb-12">
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Treating Clinician
                  </h2>
                  <SearchDropdown
                    prefix=":user:"
                    displayFields={["firstName", "lastName", "email"]}
                    onSelection={(user) => setTreatingClinician(user)}
                    placeholder={"Search for a treating clinician"}
                  />
                </div>

                {treatingClinician && (
                  <div className="mt-4 p-4 mb-4 border border-gray-300 rounded-md flex items-center">
                    <h3 className="text-sm font-semibold leading-7 text-gray-900 mr-4">
                      Treating Clinician:
                    </h3>
                    <p className="text-sm leading-6 text-gray-600">
                      {treatingClinician.firstName} {treatingClinician.lastName}
                      ,{treatingClinician.specialization}
                    </p>
                  </div>
                )}

                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Supervising Clinician
                  </h2>
                  <SearchDropdown
                    prefix=":user:"
                    displayFields={["firstName", "lastName", "email"]}
                    onSelection={(user) => setSupervisingClinician(user)}
                    placeholder={"Search for a supervising clinician"}
                  />
                </div>

                {supervisingClinician && (
                  <div className="mt-4 p-4 mb-4 border border-gray-300 rounded-md flex items-center">
                    <h3 className="text-sm font-semibold leading-7 text-gray-900 mr-4">
                      Supervising Clinician:
                    </h3>
                    <p className="text-sm leading-6 text-gray-600">
                      {supervisingClinician.firstName}{" "}
                      {supervisingClinician.lastName},{" "}
                      {supervisingClinician.specialization}
                    </p>
                  </div>
                )}
              </div>
            </>
          )}

          {/* Save and Cancel Buttons */}
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              onClick={onClose}
              className="text-sm font-semibold leading-6 text-gray-900">
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
