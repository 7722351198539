import React, { useState, useEffect } from "react";
import InvoiceGenerator from "../../applicationUi/components/InvoiceGenerator";
import { useDispatch } from "react-redux";
import { createPayroll, updatePayroll } from "../../store/thunks/payrollThunk";
import AppointmentSummary from "../../applicationUi/components/appointments/AppointmentSummary";

function HRPayrollDetails({
  appointment,
  invoice,
  payrollId,
  selectedPeriod,
  selectedPeriodDates,
  submitBy,
  payBy,
  onUpdateSuccess,
}) {
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showInvoiceGenerator, setShowInvoiceGenerator] = useState(false);
  const [isWideSidePanelOpen, setIsWideSidePanelOpen] = useState(false);

  const [payPercentage, setPayPercentage] = useState(
    appointment.percentReceived || 45
  ); // Default to 45%
  const [calculatedAmount, setCalculatedAmount] = useState(
    invoice ? invoice.amount : 0
  );

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();

  console.log("recvd appt", appointment);
  console.log("recvd invoice", invoice);
  console.log("recvd payrollId", payrollId);

  useEffect(() => {
    if (invoice) {
      setCalculatedAmount(invoice.amount);
    }
  }, [invoice]);

  if (!appointment) {
    return <div>IN HRPayrollDetails: No appointment details available.</div>;
  }

  const handleInvoiceClick = (invoice) => {
    setSelectedInvoice(invoice);
    setShowInvoiceGenerator(true);
  };

  const handleAppointmentClick = () => {
    setIsWideSidePanelOpen(true);
  };

  const handleCloseWideSidePanel = () => {
    setIsWideSidePanelOpen(false);
  };

  const handlePercentageChange = (e) => {
    const percentage = parseFloat(e.target.value);
    setPayPercentage(percentage);
    if (invoice) {
      setCalculatedAmount(((invoice.amount * percentage) / 100).toFixed(2));
    }
  };

  const handleSavePayroll = async () => {
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    const payrollData = {
      appointmentId: appointment._id,
      clinicianId: appointment.clinician._id,
      invoiceId: invoice ? invoice._id : null,
      originalAmount: invoice ? invoice.amount : 0,
      percentReceived: payPercentage,
      payrollAmount: parseFloat(calculatedAmount),
      payPeriod: selectedPeriod,
      payPeriodDates: selectedPeriodDates,
      submitBy: submitBy,
      payBy: payBy,
      status: "Pending",
    };

    try {
      await dispatch(createPayroll(payrollData)).unwrap();
      setSuccess(true);
    } catch (err) {
      setError("Failed to save payroll. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatePayroll = async () => {
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    const updateData = {
      percentReceived: payPercentage,
      payrollAmount: parseFloat(calculatedAmount),
    };

    try {
      await dispatch(
        updatePayroll({ id: payrollId, updateData }) // Use payrollId here
      ).unwrap();
      setSuccess(true);
      onUpdateSuccess(); // Call the onUpdateSuccess callback
    } catch (err) {
      setError("Failed to update payroll. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const isCompleted =
    appointment.status?.includes("Soap Notes Complete") &&
    invoice &&
    invoice.status?.toLowerCase() === "paid";

  return (
    <div className="px-4 sm:px-0">
      <div className="border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <div className="flex items-start justify-between my-4">
                <h3 className="text-base font-semibold leading-7 text-gray-900">
                  Appointment Information
                </h3>
              </div>

              {/* Appointment Details */}
              <div
                className="bg-indigo-100 rounded-lg shadow-lg p-1 my-4 cursor-pointer hover:bg-indigo-300 transition duration-200"
                onClick={handleAppointmentClick}>
                <div className="bg-white px-4 py-6 rounded-tl rounded-tr sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Clinician
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                    {appointment.clinician
                      ? `${appointment.clinician.firstName} ${appointment.clinician.lastName}`
                      : "Clinician info unavailable"}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Client
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                    {appointment.client
                      ? `${appointment.client.firstName} ${appointment.client.lastName}`
                      : "Client info unavailable"}
                  </dd>
                </div>
                <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Service
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                    {appointment.title || "Service info unavailable"}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-6 rounded-bl rounded-br sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Service Date
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                    {appointment.start
                      ? new Intl.DateTimeFormat("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        }).format(new Date(appointment.start))
                      : "Date unavailable"}
                  </dd>
                </div>
              </div>
            </div>

            <div>
              <div className="flex items-start justify-between my-4">
                <h3 className="text-base font-semibold leading-7 text-gray-900">
                  Invoice Information
                </h3>
              </div>

              {/* Invoice Details */}
              <div
                className="bg-indigo-100 rounded-lg shadow-lg p-1 my-4 cursor-pointer hover:bg-indigo-300 transition duration-200"
                onClick={() => handleInvoiceClick(invoice)}>
                {invoice ? (
                  <>
                    <div className="bg-white px-4 py-6 rounded-tl rounded-tr sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Invoice ID
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                        {invoice.invoiceId}
                      </dd>
                    </div>
                    <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Invoice Amount
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                        ${invoice.amount.toFixed(2)}
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-6 rounded-bl rounded-br sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                      <dt className="text-sm font-medium leading-6 text-gray-900">
                        Invoice Status
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                        {invoice.status}
                      </dd>
                    </div>
                  </>
                ) : (
                  <div className="bg-white px-4 py-6 rounded-lg text-center">
                    No invoice available
                  </div>
                )}
              </div>

              {showInvoiceGenerator && selectedInvoice && (
                <InvoiceGenerator data={selectedInvoice} />
              )}
            </div>
          </div>

          {/* Adjust Pay Percentage */}
          <div className="flex items-start justify-between my-4">
            <h3 className="text-base font-semibold leading-7 text-gray-900">
              Adjust Pay Percentage
            </h3>
          </div>
          <div className="border border-indigo-600 rounded-lg shadow-lg p-1 my-4">
            <div className="bg-white px-4 py-6 rounded-tl rounded-tr sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Pay Percentage
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                <input
                  type="number"
                  value={payPercentage}
                  onChange={handlePercentageChange}
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  min="0"
                  max="100"
                />
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-6 rounded-bl rounded-br sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Calculated Amount
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 sm:col-span-2">
                ${calculatedAmount}
              </dd>
            </div>
          </div>
        </dl>
      </div>

      {isWideSidePanelOpen && (
        <AppointmentSummary
          title="Appointment Summary"
          open={isWideSidePanelOpen}
          onClose={handleCloseWideSidePanel}
          appointment={appointment}
        />
      )}

      <div className="mt-6 flex justify-end">
        <button
          onClick={handleSavePayroll}
          className={`rounded-md px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
            isLoading || !(invoice && invoice.status.toLowerCase() === "paid")
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
          }`}
          disabled={
            isLoading || !(invoice && invoice.status.toLowerCase() === "paid")
          }>
          {isLoading ? "Saving..." : "Save Payroll"}
        </button>

        <button
          onClick={handleUpdatePayroll}
          className={`ml-4 rounded-md px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
            isLoading
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600"
          }`}
          disabled={isLoading}>
          {isLoading ? "Updating..." : "Update Payroll"}
        </button>

        {success && (
          <div className="mt-4 text-green-600">
            Payroll updated successfully!
          </div>
        )}
        {error && <div className="mt-4 text-red-600">{error}</div>}
      </div>
    </div>
  );
}

export default HRPayrollDetails;
