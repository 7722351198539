import { useState } from "react";
import InputField from "../../applicationUi/components/intake/InputField";
import CheckboxField from "../../applicationUi/components/intake/CheckboxField";
import TextAreaField from "../../applicationUi/components/intake/TextAreaField";

const ParentGuardianSection = ({ formData, handleChange }) => {
  const [showParent2, setShowParent2] = useState(false); // State to show/hide Parent 2

  const toggleParent2 = () => setShowParent2(!showParent2);

  return (
    <section>
      <h2 className="text-lg font-bold">Parent/Guardian Information</h2>

      {/* Parent 1 Fields */}
      <h3 className="font-semibold">Parent 1</h3>
      <div className="grid grid-cols-3 gap-4">
        <InputField
          label="First Name"
          name="parentGuardian1.firstName"
          value={formData.parentGuardian1.firstName}
          onChange={handleChange}
        />
        <InputField
          label="Last Name"
          name="parentGuardian1.surname"
          value={formData.parentGuardian1.surname}
          onChange={handleChange}
        />
        <InputField
          label="Email"
          name="parentGuardian1.email"
          value={formData.parentGuardian1.email}
          onChange={handleChange}
        />
        <InputField
          label="Cell Phone"
          name="parentGuardian1.cellPhone"
          value={formData.parentGuardian1.cellPhone}
          onChange={handleChange}
        />
        <InputField
          label="Home Phone"
          name="parentGuardian1.homePhone"
          value={formData.parentGuardian1.homePhone}
          onChange={handleChange}
        />
        <InputField
          label="Work Phone"
          name="parentGuardian1.workPhone"
          value={formData.parentGuardian1.workPhone}
          onChange={handleChange}
        />
        <InputField
          label="Occupation"
          name="parentGuardian1.occupation"
          value={formData.parentGuardian1.occupation}
          onChange={handleChange}
        />
        <CheckboxField
          label="Main Contact"
          name="parentGuardian1.mainContact"
          checked={formData.parentGuardian1.mainContact}
          onChange={handleChange}
        />
      </div>

      {/* Full width Notes field for Parent 1 */}
      <TextAreaField
        label="Notes"
        name="parentGuardian1.notes"
        value={formData.parentGuardian1.notes}
        onChange={handleChange}
      />

      {/* Toggle button for Parent 2 */}
      <button
        type="button"
        onClick={toggleParent2}
        className="mt-4 mb-4 text-indigo-500 hover:text-indigo-700 focus:outline-none">
        {showParent2 ? "- Hide Parent 2" : "+ Add Parent 2"}
      </button>

      {showParent2 && (
        <>
          {/* Parent 2 Fields */}
          <h3 className="font-semibold">Parent 2</h3>
          <div className="grid grid-cols-3 gap-4">
            <InputField
              label="First Name"
              name="parentGuardian2.firstName"
              value={formData.parentGuardian2.firstName}
              onChange={handleChange}
            />
            <InputField
              label="Last Name"
              name="parentGuardian2.surname"
              value={formData.parentGuardian2.surname}
              onChange={handleChange}
            />
            <InputField
              label="Email"
              name="parentGuardian2.email"
              value={formData.parentGuardian2.email}
              onChange={handleChange}
            />
            <InputField
              label="Cell Phone"
              name="parentGuardian2.cellPhone"
              value={formData.parentGuardian2.cellPhone}
              onChange={handleChange}
            />
            <InputField
              label="Home Phone"
              name="parentGuardian2.homePhone"
              value={formData.parentGuardian2.homePhone}
              onChange={handleChange}
            />
            <InputField
              label="Work Phone"
              name="parentGuardian2.workPhone"
              value={formData.parentGuardian2.workPhone}
              onChange={handleChange}
            />
            <InputField
              label="Occupation"
              name="parentGuardian2.occupation"
              value={formData.parentGuardian2.occupation}
              onChange={handleChange}
            />
            <CheckboxField
              label="Main Contact"
              name="parentGuardian2.mainContact"
              checked={formData.parentGuardian2.mainContact}
              onChange={handleChange}
            />
          </div>

          {/* Full width Notes field for Parent 2 */}
          <TextAreaField
            label="Notes"
            name="parentGuardian2.notes"
            value={formData.parentGuardian2.notes}
            onChange={handleChange}
          />
        </>
      )}
    </section>
  );
};

export default ParentGuardianSection;
