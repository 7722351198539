import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { setIntakeForm, setLoading, setError } from "../slices/intakeSlice";
import { generateConfig } from "../../utils/api";

// Create a new intake form
export const createIntakeForm = createAsyncThunk(
  "intake/createIntakeForm",
  async (intakeData, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.post(
        `${baseURL}/api/intake`,
        intakeData,
        config
      );
      dispatch(setIntakeForm(response.data));
      return response.data;
    } catch (error) {
      dispatch(setError(error.message));
      return rejectWithValue(error.message);
    }
  }
);

// Update intake form section by section
export const updateIntakeForm = createAsyncThunk(
  "intake/updateIntakeForm",
  async ({ id, updatedData }, { dispatch, getState, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const state = getState();
      const token = state.auth.token;
      const { baseURL, config } = generateConfig({ token });

      const response = await axios.patch(
        `${baseURL}/api/intake/${id}`,
        updatedData,
        config
      );
      dispatch(setIntakeForm(response.data));
      return response.data;
    } catch (error) {
      dispatch(setError(error.message));
      return rejectWithValue(error.message);
    }
  }
);
