const BeginIntakeSection = ({ onStart }) => {
  return (
    <div className="text-center">
      <h1 className="text-xl font-bold mb-4">Start Intake Form</h1>
      <p className="mb-6">
        Are you ready to start the intake form? Your progress will be saved
        after each section.
      </p>
      <button onClick={onStart} className="btn btn-primary">
        Start
      </button>
    </div>
  );
};

export default BeginIntakeSection;
    