// src/store/store.js

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { thunk } from "redux-thunk";
import appointmentReducer from "./slices/appointmentsSlice";
import authReducer from "./slices/authSlice";
import clientReducer from "./slices/clientsSlice";
import clinicianReducer from "./slices/cliniciansSlice";
import clinicReducer from "./slices/clinicSlice";
import insurerReducer from "./slices/insurerSlice";
import productReducer from "./slices/productsSlice";
import referralsReducer from "./slices/referralsSlice";
import searchReducer from "./slices/searchApiSlice";
import todoReducer from "./slices/todoSlice"; // Import the To-Do slice
import pdfReducer from "./slices/pdfSlice";
import invoiceReducer from "./slices/invoiceSlice";
import payrollReducer from "./slices/payrollSlice";
import userReducer from "./slices/userSlice";
import uploadsReducer from "./slices/uploadsSlice";
import serviceReducer from "./slices/servicesSlice";
import waagReducer from "./slices/waagSlice";
import meetingReducer from "./slices/meetingSlice";
import notesReducer from "./slices/notesSlice";
import rolesReducer from "./slices/rolesSlice";
import intakeReducer from "./slices/intakeSlice";
import appStateReducer from "./slices/appStateSlice";
import paymentsReducer from "./slices/paymentsSlice";
import phoneCallReducer from "./slices/phoneCallSlice";
import clientNoteReducer from "./slices/clientNotesSlice";

// Define the current version of your store
const CURRENT_STORE_VERSION = "1.0.1"; // Update the version when you make breaking changes to the store

// Redux Persist Configuration with versioning and migration logic
const persistConfig = {
  key: "ClinicFlow",
  storage,
  version: CURRENT_STORE_VERSION,
  migrate: (state) => {
    if (
      state &&
      state._persist &&
      state._persist.version !== CURRENT_STORE_VERSION
    ) {
      // If the stored version is different, clear the persisted state
      storage.removeItem("persist:ClinicFlow"); // Clear old state
      return Promise.resolve({}); // Return an empty object to reinitialize the store
    }
    return Promise.resolve(state); // No migration needed if versions match
  },
};

const rootReducer = combineReducers({
  appointments: appointmentReducer,
  auth: authReducer,
  clients: clientReducer,
  clinicians: clinicianReducer,
  clinics: clinicReducer,
  insurer: insurerReducer,
  products: productReducer,
  referrals: referralsReducer,
  search: searchReducer,
  todos: todoReducer,
  pdf: pdfReducer,
  invoices: invoiceReducer,
  payrolls: payrollReducer,
  users: userReducer,
  uploads: uploadsReducer,
  services: serviceReducer,
  waag: waagReducer,
  meetings: meetingReducer,
  notes: notesReducer,
  roles: rolesReducer,
  intake: intakeReducer,
  appState: appStateReducer,
  payments: paymentsReducer,
  phoneCalls: phoneCallReducer,
  clientNotes: clientNoteReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
      },
    }).concat(thunk),
});

const persistor = persistStore(store);

export { store, persistor };

export * from "./thunks/appointmentsThunk";
export * from "./thunks/authThunk";
export * from "./thunks/clientsThunk";
export * from "./thunks/cliniciansThunk";
export * from "./thunks/clinicThunk";
export * from "./thunks/insurerThunk";
export * from "./thunks/productsThunk";
export * from "./thunks/referralsThunk";
export * from "./thunks/searchApiThunk";
export * from "./thunks/todoThunk";
export * from "./thunks/pdfThunk";
export * from "./thunks/invoiceThunk";
export * from "./thunks/payrollThunk";
export * from "./thunks/userThunk";
export * from "./thunks/uploadsThunk";
export * from "./thunks/servicesThunk";
export * from "./thunks/waagThunk";
export * from "./thunks/meetingsThunk";
export * from "./thunks/notesThunk";
export * from "./thunks/rolesThunk";
export * from "./thunks/intakeThunk";
export * from "./thunks/appStateThunk";
export * from "./thunks/paymentsThunk";
export * from "./thunks/phoneCallThunk";
export * from "./thunks/clientNotesThunk";
